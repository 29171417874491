import React, { useState } from "react";
import "../TermsPage/Terms.css";
const TermsPage = () => {
  const [selectedOption, setSelectedOption] = useState("");

  const handleChange = (e) => {
    setSelectedOption(e.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const apiEndpoint = 'https://script.google.com/macros/s/AKfycbxI7JE4areN0-UK1h4_1N_JYn0hedt4g-OgVoOlsrgadxHobIPME5pCHXbLvWConzAT/exec';


    const formData = new FormData(e.target);

    try {
      const response = await fetch(apiEndpoint, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        console.log("Form submitted successfully!");
      } else {
        console.error("Form submission failed!");
      }
    } catch (error) {
      console.error("Error during form submission:", error);
    }
  };

  return (
    <>
    <div className="team_container">
          <div className="team_content">
            <h1 className="team_heading1">HYPERSAGE TECHNOLOGY PVT LTD - Terms and Conditions</h1>
          </div>
        </div>
      <div className="Termspage_body">
        <div className="termpage_container">
          <div className="termpage_all_content">
            <h2 className="termpage_heading2">1.Acceptance of Terms</h2>
            <p className="termpage_paragraph1">
              By accessing and using the HYPERSAGE TECHNOLOGY PVT LTD
              website,you agree to comply with and be bound by these terms and
              consitions.If you do not agree to these,please do not use the
              website
            </p>
            <h2 className="termpage_heading3"> 2 .Changes to Terms</h2>
            <p className="termpage_paragraph2">
              HYPERSAGE TECHNOLOGY PVT LTD reserves the right to modify,add,or
              remove any part of these terms at any time.It is your
              responsibility to check these terms periodically for changes.Your
              continued use of the website following the posting of changes
              constitutes acceptance of those changes.
            </p>
            <h2 className="termpage_heading4">Intellectual Property</h2>
            <p className="termpage_paragraph3">
              All content on this website,including
              text,graphics,logos,images,and software, is the property of
              HYPERSAGE TECHNOLOGY PVT LTD and is protected by intellectual
              property laws.You may not reproduce,distribute, or create
              derivative works without explicit permission.
            </p>
            <h2 className="termpage_heading5">4.User Conduct</h2>
            <p className="termpage_paragraph4">
              You agree not to engage in any conduct that may disrupt or
              interfere with the operation of the website, including but not
              limited to hacking, uploading malicious code, or attempting to
              gain unauthorized access.
            </p>
            <h2 className="termpage_heading6">5. Privacy Policy</h2>
            <p className="termpage_paragraph5">
              Your use of the website is also governed by our Privacy Policy. By
              using the website, you consent to the terms of the Privacy Policy.
            </p>
            <h2 className="termpage_heading7">6. Limitation of Liability</h2>
            <p className="termpage_paragraph6">
              HYPERSAGE TECHNOLOGY PVT LTD is not liable for any direct,
              indirect, incidental, consequential, or special damages arising
              out of or in any way connected with your use of the website.
            </p>
            <h2 className="termpage_heading8">8. Contact Information</h2>
            <p className="termpage_paragraph7">
              If you have any questions or concerns about these terms and
              conditions, please contact us at [insert your contact email].
            </p>
          </div>
        </div>
        <div className="Promotion_contact_body">
          <div className="Promotion_Form_container">
            <div className="Promotion-Form_contactInfo">
              <div className="Promotion_Form-box">
                <div className="Promotion_form_text">
                  <h2 className="Promotion-Form-text2">Let's Build</h2>
                  <h2 className="Promotion_form-text3">
                    <span className="Promotion-form-span">Future</span> Together
                  </h2>
                  <p className="Promotion_form_paragrapg">
                    Get in Touch; Together,Let's Shape a Prosperous Future.
                  </p>
                </div>
              </div>
            </div>
            {/* Contact form components */}
            <div className="Promotion_contact_form_body">
              <div className="responsive_form_promotionpage">
              <form className="PromotionPage_form" onSubmit={handleSubmit}>
      <div className="PromotionPage_form_title">Need Help?</div>
      <div className="Promotionpage_half">
        <div className="PromotionPage_items_form">
          <input
          name="Name"
            type="text"
            placeholder="Your Name"
            className="Promotionpage_inputboxes"
          />
        </div>
        <div className="PromotionPage_items_form">
          <input
          name="Email"
            type="email"
            placeholder="Your Email"
            className="Promotionpage_inputboxess"
          />
        </div>
      </div>
      <div className="PromotionPage_items_form">
      <select
          name="Finacial Planning"
          id="FinacialPlanning"
          className="Promotionpage_option"
          type="text"
          value={selectedOption}
          onChange={handleChange}
        >
          <option value="" disabled>Select Financial Planning</option>
          <option value="Software Development">Software Development</option>
          <option value="Bulk WhatsApp Message">Bulk WhatsApp Message</option>
        </select>
      </div>
      {/* <div className="PromotionPage_items_form">
        <input
         
          placeholder="Selected Financial Planning"
          className="Promotionpage_inputboxes"
          readOnly
        />
      </div> */}
      <div className="Promotionpage_form_full">
        <textarea
          name=""
          className="promotionpage_textarea_form"
          placeholder="Enter Your Message"
        ></textarea>
      </div>
      <div className="Promotionpage_form_action">
        <input
          type="submit"
          value="Send Message"
          className="Promotionpage_action_input"
        />
      </div>
    </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TermsPage;
