export const ManagemetTeamData = [
  {
    id: 1,
    name: "CMO",
    Image:
      "https://firebasestorage.googleapis.com/v0/b/hypersage-a9736.appspot.com/o/1.png?alt=media&token=b523a383-0dff-423b-af3f-ac395c443dde",
  },
  {
    id: 2,
    name: "MD",
    Image:
      "https://firebasestorage.googleapis.com/v0/b/hypersage-a9736.appspot.com/o/3.png?alt=media&token=9e349349-e9fe-43ba-b08d-0240702ea519"
  },
  {
    id: 3,
    name: "Cto",
    Image:
      "https://firebasestorage.googleapis.com/v0/b/hypersage-a9736.appspot.com/o/2.png?alt=media&token=69303161-66a5-4e4d-a98e-89d02dafaf80",
  },
  {
    id: 4,
    name: "Operation Head",
    Image:
      "https://firebasestorage.googleapis.com/v0/b/hypersage-a9736.appspot.com/o/5.png?alt=media&token=7066d884-7f3f-4fef-b400-4a5a2f4cb56f"
  },
  {
    id: 5,
    name: "Sales Head",
    Image:
      "https://firebasestorage.googleapis.com/v0/b/hypersage-a9736.appspot.com/o/7.png?alt=media&token=21d5b92a-1ec9-4a2a-b5ee-8d5bf167a69e"
  },
  {
    id: 6,
    name: "Graphic Designer",
    Image:
      "https://firebasestorage.googleapis.com/v0/b/hypersage-a9736.appspot.com/o/4.png?alt=media&token=e40ecb65-e0b2-4eae-ad03-d17b5a307fea"
  },
  {
    id: 7,
    name: "Legal Head",
    Image:
      "https://firebasestorage.googleapis.com/v0/b/hypersage-a9736.appspot.com/o/6.png?alt=media&token=f02bf0e1-d4b5-44fb-b944-c9b2c78f4e78"
  },
];
