import React,{useEffect,useState,useMemo} from "react";
import "../Digital Markting/Digital.css";
import Digitallogo from "../../Assets/digital logo.png";
import Digitallogo2 from "../..//Assets/digital logo2.png";
import Digitallogo3 from "../../Assets/digital logo3.png";
import LetsBuildPage from "../LetsBuildPage/LetsBuildPage";
const Digital = () => {
  const [formData, setFormData] = useState({
    name: '',
    number: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    showPleaseWaitMessage();

    const formDataToSubmit = new FormData();
    formDataToSubmit.append('name', formData.name);
    formDataToSubmit.append('number', formData.number);
    formDataToSubmit.append('email', formData.email);
    formDataToSubmit.append('message', formData.message);

    fetch('https://script.google.com/macros/s/AKfycbx93p-Ns5kgj6Jobe6SNx777JY6o3oOWAwCVgh4UjE7wX2Jtax1_QpOx_oVHgGXC1Rr0w/exec', {
      method: 'POST',
      body: formDataToSubmit
    })
    .then(response => response.text())
    .then(data => {
      console.log(data); // Handle response from server if needed
      alert('Form submitted successfully!');
      setFormData({ name: '', number: '', message: '',email:"" });
     
    })
    .catch(error => {
      console.error('Error:', error);
      alert('An error occurred while submitting the form.');
    })
    .finally(() => {
      hidePleaseWaitMessage();
    });
  };

  const showPleaseWaitMessage = () => {
    const submitButton = document.getElementById('submitButton');
    submitButton.innerText = 'Please wait...';
    submitButton.disabled = true;
  };

  const hidePleaseWaitMessage = () => {
    const submitButton = document.getElementById('submitButton');
    submitButton.innerText = 'Submit';
    submitButton.disabled = false;
  };

  const memoizedFormData = useMemo(() => ({
    name: formData.name,
    number: formData.number,
    email: formData.email,
    message: formData.message
  }), [formData]);
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  return (
    <>
      <div className="Digital_page_body">
        <div className="Digital-container">
          {/* image section */}
          <div className="Digital_top_body">
            <div className="Digital_bgImage"></div>
            <div className="Digital_content-body">
              <h1 className="Digital_heading-1">Digital Marketing</h1>
              <p className="Digital_paragraph_1">
                Your Strategic Digital Marketing Ally
              </p>
            </div>
            <div className="Digital-all-paragraph">
              <p className="Digital_paragraph_2">
                Welcome to Hypersage,where we extend our expertise beyond
                software solutions to empower your brand through cutting-edge
                digital marketing strategies. In the ever-evolving digital
                landscape,we understand the importance of a strong online
                presence and effective marketing campaigns.
              </p>
              <p className="Digital_paragraph-3">
                Our digital marketing services are crafted to elevate your
                brand,drive targeted traffic,and maximize your online
                reach.Here's how Hypersage can be your trusted partner in the
                realm of digital marketing:
              </p>
              <p className="Digital_paragraph_4">
                At Hypersage,we are not just a digital marketing agency; we are
                your dedicated partner in achieving online success. Join forces
                with us to navigate the dynamic digital landscape,enhance your
                brand visibility,and achieve measurable results in the
                ever-evolving online marketplace.Experience the Hypersage
                difference in digital marketing today.
              </p>
            </div>
          </div>
          <div className="Digital_card_container">
            {/* card one */}
            <div className="Digital_card">
              <img src={Digitallogo} alt="" className="Digital_card-image" />
              <div className="Digital_card_content">
                <button className="Digital_card_button">Buy Now</button>
              </div>
            </div>
            {/* card two */}
            <div className="Digital_card">
              <img src={Digitallogo2} alt="" className="Digital_card-image" />
              <div className="Digital_card_content">
                <button className="Digital_card_button">Buy Now</button>
              </div>
            </div>
            {/* card three */}
            <div className="Digital_card">
              <img src={Digitallogo3} alt="" className="Digital_card-image" />
              <div className="Digital_card_content">
                <button className="Digital_card_button">Buy Now</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* lets build page */}
      
      <div id="NSMSPAGE_CONTACT_BODY">
          <div id="NSMSPAGE_FORM-CONTAINER">
            <div id="NSMSPAGE_FORM_CONTACT_INFO">
              <div id="NSMSPAGE_FORM-BOX">
                <div id="NSMSPAGE_FORM_TEXT">
                  <h2 id="NSMSPAGE_FORM_TEXT2">Let's Build</h2>
                  <h2 id="NSMAPHE_FORM_TEXT3">
                    <span id="NSMSPAGE_SPAN_FORM">Future</span> Together
                  </h2>
                  <p id="NSMSPAGE_PARAGRAPH_ONE">
                    Get In Touch; Together,Let's Shape a Prosperous Future.
                  </p>
                </div>
              </div>
            </div>
            {/* form */}
            <div id="NSMSPAGE_CONTACT_FORM_BODY">
              <div id="NSMSPAGE_ALL_FORM">
              <form onSubmit={handleSubmit} id="NSMSPAGE_FORM">
      <div id="NSMSPAGE_FORM_TITLE">Need Help?</div>
      <div id="NSMSPAGE_FORM_HALF">
        <div id="NSMSPAGE_ITEMS_FORM">
          <input
            type="text"
            name="name"
            id="NSMSPAGE_INPUT_BOX"
            value={memoizedFormData.name}
            onChange={handleChange}
            placeholder="Name"
          />
        </div>
        <div id="NSMSPAGE_ITEMS_FORM">
          <input
            type="number"
            name="number"
            id="NSMSPAGE_INPUT_BOX_Two"
            value={memoizedFormData.number}
            onChange={handleChange}
            placeholder="Number"
          />
        </div>
      </div>
      <div id="NSMSPAGE_ITEMS_FORM">
        <input
          type="email"
          name="email"
          id="NSMSPAGE_INPUT_BOX_Two"
          value={memoizedFormData.email}
          onChange={handleChange}
          placeholder="Email ID"
        />
      </div>
      <div id="NSMSPAGE_Form_Full">
        <textarea
          name="message"
          style={{color:"#000"}}
          value={memoizedFormData.message}
          onChange={handleChange}
          placeholder="Your Message"
        ></textarea>
      </div>
      <div id="NSMSPAGE_Form_Action">
      <button type="submit" id="submitButton" onClick={handleSubmit} className="btn">Submit</button>
      </div>
    </form>
              </div>
            </div>
          </div>
        </div>
    </>
  );
};
export default Digital;
