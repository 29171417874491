import React from "react";
import "../Privacy/Privacy.css";
import LetsBuildPage from "../LetsBuildPage/LetsBuildPage";

const PrivacyPage = () => {
  return (
    <>
        <div>
        <div className="privacy_container">
          <div className="privacy_content">
            <h1 className="privacy_heading1">Privacy Ploicy</h1>
            <p className="privacy_paragraph1">
            Welcome to HYPERSAGE TECHNOLOGY PVT LTD ("we", "us", or "our"). This Privacy Policy outlines how we collect, use, and protect the information you provide when using our IT services and consulting.
            </p>
          </div>
        </div>
        </div>
      <div className="PrivacyPage_body">
        <div className="Privacypage_container">
          <div className="Privacypage_all_paragraph">
            {/* <p className="Privacypage_paragraph1">
              Welcome to HYPERSAGE TECHNOLOGY PVT LTD ("we","us",or "our").This
              Privacy Policy outlines how we collect,use, and protect the
              information you provide when using our IT services and consulting.
            </p> */}
            <h2 className="Privacypage_heading2">Information We Collect</h2>
            <p className="Privacypage_paragraph2">
              We may collect the following types of information:
            </p>
            <ul className="Privacypage_ul">
              <li className="Privacypage_li">
                Client Information: Names,contact details,and other information
                provided during consulting engagements.
              </li>
              <li className="Privacypage_li">
                Technical Data: Information related to IT
                systems,configurations, and usage.
              </li>
              <li className="Privacypage_li">
                Log Data:Details about your interactions with our services,
                including IP addresses and device information
              </li>
            </ul>
            <h2 className="Privacypage_heading3">
              How We Use Your Information
            </h2>
            <p className="Privacypage_paragraph3">
              We use the collected information for the following purposes:
            </p>
            <ul className="Privacypage_ul2">
              <li className="Privacypage_li2">
                Providing IT services and consulting tailored to your needs.
              </li>
              <li className="Privacypage_li2">
                Ensuring the security and confidentiality of client information.
              </li>
              <li className="Privacypage_li2">
                Improving our services and addressing technical issues.
              </li>
            </ul>
            <h2 className="Privacypage_heading4">Client Confidentiality</h2>
            <p className="Privacypage_paragraph4">
              We understand the sensitivity of the information shared during
              consulting engagements.We are committed to maintaining the
              confidentiality of client data and ensuring its secure handling.
            </p>
            <h2 className="Privacypage_heading5">Security Measures</h2>
            <p className="Privacypage_paragraph5">
              We implement industry-standard security measures to protect your
              data.However, no method of transmission over the internet or
              electronic storage is 100% secure.
            </p>
            <h2 className="Privacypage_heading6">Third-Party Disclosure</h2>
            <p className="Privacypage_paragraph6">
              We do not sell or rent your personal information to third
              parties.However, we may share information with trusted partners
              and service providers to enhance our services.
            </p>
            <h2 className="Privacypage_heading7">Refund Olicy</h2>
            <p className="Privacypage_paragrph7">
              We strive to provide exxcellent services.if,for any reason,you are
              not satisfied with our services,we offer a refund within 30 days
              of your purchase.To request a refund,please contact us at
              <a href="info@hypersagetech.com">info@hypersagetech.com</a> with your order details and the reason for
              your request.
            </p>
            <h2 className="Privacypage_heading8">Contact Us</h2>
            <h4 className="Privacypage-address">
              HYPERSAGE TECHNOLOGY PVT LTD.
            </h4>
            <h4 className="Privacypage-address2">
              C/o-k N Sammana, No-32, Kormangala 8th Block,
            </h4>
            <h4 className="Privacypage_address3">
              Bangalore, Karnataka, India, 560095
            </h4>
            <p className="Privacypage_paragraph8">
              If you have any questions about this Privacy Policy, please
              contact us at <a href="info@hypersagetech.com">info@hypersagetech.com</a>
            </p>
          </div>
        </div>
      </div>
      <LetsBuildPage/>
    </>
  );
};
export default PrivacyPage;
