import React,{useState} from 'react'
import'./gallery.css';
import Img1 from '../../Assets/WhatsApp Image 2024-03-02 at 1.00.22 PM (1).jpeg'
import Img2 from '../../Assets/WhatsApp Image 2024-03-02 at 1.00.22 PM.jpeg'
import Img3 from '../../Assets/WhatsApp Image 2024-03-02 at 1.00.23 PM (1).jpeg'
import Img4 from '../../Assets/WhatsApp Image 2024-03-02 at 1.00.23 PM.jpeg'

    
import CloseIcon from '@mui/icons-material/Close';

export default function Gallery() {
    let data =[
        {
          id: 1,
          imgSrc: Img1,
        },
        {
            id: 2,
            imgSrc: Img2,
          },
          // {
          //   id: 3,
          //   imgSrc: Img3,
          // },
          {
            id: 4,
            imgSrc: Img4,
          },
          
      
    
        ]
    const [model, setModel]=  useState(false);
    const[tempimgSrc, setTempImgSrc] = useState('');
    const getIMG = (imgSrc)=>{
       setTempImgSrc(imgSrc);
       setModel(true);
    }
  return (
    <div style={{marginTop:"8rem"}}>
          <h1 style={{textAlign: 'center',color:"white"}}> Gallery </h1>
  

        <div className={model?"model open":"model"}>
            <img src={tempimgSrc}/>
           <CloseIcon onClick={()=> setModel(false)} />
        </div>
        <div className ="gallery">
            {data.map((item, index)=>{
           return(
          <div className="pics" key={index} onClick={()=> getIMG(item.imgSrc)}>
           <img src={item.imgSrc} style={{width: '100%'}} />
          </div>
           )
            })}
        </div>
    </div>
      
     
  
        
  
  )
}
