import React,{useEffect,useState,useMemo} from "react";
import "../SoftwereSolution/SoftwereSolution.css";
import hba from "../../Assets/Hba.png";
import hbu from "../../Assets/Hbu.png";
import hbps from "../../Assets/Hbps.png";
import LetsBuildPage from "../LetsBuildPage/LetsBuildPage";
const SoftwereSolution = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  const [formData, setFormData] = useState({
    name: '',
    number: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    showPleaseWaitMessage();

    const formDataToSubmit = new FormData();
    formDataToSubmit.append('name', formData.name);
    formDataToSubmit.append('number', formData.number);
    formDataToSubmit.append('email', formData.email);
    formDataToSubmit.append('message', formData.message);

    fetch('https://script.google.com/macros/s/AKfycbzRh3uECRGFaShoMvP0kKOhAQTPILPvPpdueSJrZwhFSpEH3qWQCt0-2OD6cpqya8Gs/exec', {
      method: 'POST',
      body: formDataToSubmit
    })
    .then(response => response.text())
    .then(data => {
      console.log(data); // Handle response from server if needed
      alert('Form submitted successfully!');
      setFormData({ name: '', number: '', message: '',email:"" });
     
    })
    .catch(error => {
      console.error('Error:', error);
      alert('An error occurred while submitting the form.');
    })
    .finally(() => {
      hidePleaseWaitMessage();
    });
  };

  const showPleaseWaitMessage = () => {
    const submitButton = document.getElementById('submitButton');
    submitButton.innerText = 'Please wait...';
    submitButton.disabled = true;
  };

  const hidePleaseWaitMessage = () => {
    const submitButton = document.getElementById('submitButton');
    submitButton.innerText = 'Submit';
    submitButton.disabled = false;
  };

  const memoizedFormData = useMemo(() => ({
    name: formData.name,
    number: formData.number,
    email: formData.email,
    message: formData.message
  }), [formData]);
  return (
    <div>
      <div className="softwere_container">
        <div className="content">
          <h1>Software Solution</h1>
          <h4>Your Comprehensive Software Solutions Partner</h4>
        </div>
      </div>
      <div className="para-img">
        <div className="paras">
          <p>
            Welcome to Hypersage, your one-stop destination for cutting-edge
            software solutions. We offer a diverse range of services tailored to
            meet all your business needs. Whether you require custom software
            development, innovative mobile applications, or user-friendly
            websites, Hypersage excels in delivering solutions that elevate your
            digital presence.
          </p>
          <p>
            Our team of experienced developers and designers is dedicated to
            creating bespoke software solutions that align seamlessly with your
            unique requirements. We specialize in streamlining operations with
            advanced ERP systems, enhancing customer engagement through
            intuitive mobile apps, and establishing a robust online presence
            with tailored websites.
          </p>
          <p>
            At Hypersage, we are committed to turning your vision into reality.
            With a focus on quality, efficiency, and client satisfaction, we
            take pride in being the go-to partner for businesses seeking
            top-notch software expertise. Experience the power of tailored
            solutions with Hypersage and transform your digital landscape today.
          </p>
        </div>
        <div className="s-img">
          <div className="sms-card">
          <div className="btn-img">
            <img src={hba} alt="" />
            <button className="Btn">Buy Now</button>
          </div>
          </div>
          <div className="btn-img">
            <img src={hbu} alt="" />
            <button className="Btn">Buy Now</button>
          </div>
          <div className="btn-img">
            <img src={hbps} alt="" />
            <button className="Btn">Buy Now</button>
          </div>
        </div>
      </div>
      {/* contact lets */}
      <div id="NSMSPAGE_CONTACT_BODY">
          <div id="NSMSPAGE_FORM-CONTAINER">
            <div id="NSMSPAGE_FORM_CONTACT_INFO">
              <div id="NSMSPAGE_FORM-BOX">
                <div id="NSMSPAGE_FORM_TEXT">
                  <h2 id="NSMSPAGE_FORM_TEXT2">Let's Build</h2>
                  <h2 id="NSMAPHE_FORM_TEXT3">
                    <span id="NSMSPAGE_SPAN_FORM">Future</span> Together
                  </h2>
                  <p id="NSMSPAGE_PARAGRAPH_ONE">
                    Get In Touch; Together,Let's Shape a Prosperous Future.
                  </p>
                </div>
              </div>
            </div>
            {/* form */}
            <div id="NSMSPAGE_CONTACT_FORM_BODY">
              <div id="NSMSPAGE_ALL_FORM">
              <form onSubmit={handleSubmit} id="NSMSPAGE_FORM">
      <div id="NSMSPAGE_FORM_TITLE">Need Help?</div>
      <div id="NSMSPAGE_FORM_HALF">
        <div id="NSMSPAGE_ITEMS_FORM">
          <input
            type="text"
            name="name"
            id="NSMSPAGE_INPUT_BOX"
            value={memoizedFormData.name}
            onChange={handleChange}
            placeholder="Name"
          />
        </div>
        <div id="NSMSPAGE_ITEMS_FORM">
          <input
            type="number"
            name="number"
            id="NSMSPAGE_INPUT_BOX_Two"
            value={memoizedFormData.number}
            onChange={handleChange}
            placeholder="Number"
          />
        </div>
      </div>
      <div id="NSMSPAGE_ITEMS_FORM">
        <input
          type="email"
          name="email"
          id="NSMSPAGE_INPUT_BOX_Two"
          value={memoizedFormData.email}
          onChange={handleChange}
          placeholder="Email ID"
        />
      </div>
      <div id="NSMSPAGE_Form_Full">
        <textarea
          name="message"
          style={{color:"#000"}}
          value={memoizedFormData.message}
          onChange={handleChange}
          placeholder="Your Message"
        ></textarea>
      </div>
      <div id="NSMSPAGE_Form_Action">
      <button type="submit" id="submitButton" onClick={handleSubmit} className="btn">Submit</button>
      </div>
    </form>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default SoftwereSolution;
