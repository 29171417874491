import React, { useState, useEffect,useMemo } from "react";
import "./Home.css";
import Clienttrust from "../Marquee page/Clienttrust";
import OurServicePage from "../OurService Page/OurService";
import Slide from "../Marquee page/Slide";
import PricingHome from "../Pricinghomepage/PricingHome";
import TestimonialPage from "../Testimonal page/Testimonal";
import FaqAccordion from "../Accordianpage/Accordian";
import ManagementPage from "../Management Page/Managementpage";
import AlllogoPage from '../Digitalfile page/AlllogoPage'
import AlllogoPage2 from '../Digitalfile page/AlllogoPage2'
import AlllogoPage3 from '../Digitalfile page/AlllogoPage3'
import Methodology from "../Methodology/Methodology";
import Page from "../Page/Page";
import Modal from "react-modal";
import { Tick } from 'react-crude-animated-tick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
const Home = () => {
  const [selectedOption, setSelectedOption] = useState("");
  // useEffect(() => {
  //   // 👇️ scroll to top on page load
  //   window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  // }, []);
  const [formData, setFormData] = useState({
    name: '',
    number: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    showPleaseWaitMessage();

    const formDataToSubmit = new FormData();
    formDataToSubmit.append('name', formData.name);
    formDataToSubmit.append('number', formData.number);
    formDataToSubmit.append('email', formData.email);
    formDataToSubmit.append('message', formData.message);

    fetch('https://script.google.com/macros/s/AKfycbyRNuF8sDfmfFbEWsy6Xmqf8Cv6SDbW7mpaiTcJ43ly37onNNnqtKvwaxvkmQ3x1oe-/exec', {
      method: 'POST',
      body: formDataToSubmit
    })
    .then(response => response.text())
    .then(data => {
      console.log(data); // Handle response from server if needed
      alert('Form submitted successfully!');
      setFormData({ name: '', number: '', message: '',email:"" });
      setShowForm(false)
    })
    .catch(error => {
      console.error('Error:', error);
      alert('An error occurred while submitting the form.');
    })
    .finally(() => {
      hidePleaseWaitMessage();
    });
  };

  const showPleaseWaitMessage = () => {
    const submitButton = document.getElementById('submitButton');
    submitButton.innerText = 'Please wait...';
    submitButton.disabled = true;
  };

  const hidePleaseWaitMessage = () => {
    const submitButton = document.getElementById('submitButton');
    submitButton.innerText = 'Submit';
    submitButton.disabled = false;
  };

  const memoizedFormData = useMemo(() => ({
    name: formData.name,
    number: formData.number,
    email: formData.email,
    message: formData.message
  }), [formData]);

  const [showForm, setShowForm] = useState(false);
  const [modal, setModal] = useState(false);
  const closeModal = () => {
    setModal(false);
  }
  const customStyles2 = {
    content: {
      top: "45%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "600px",
      borderRadius: "15px",
      height: "300px",
      zIndex: "200",
    },
  };
  const toggleForm = () => {
    setShowForm(!showForm);
  };

  return (
    <>
      <div className="home_main">
        <div className="Homepage_container">
          <div className="Homepage_content">
            <h3 className="Homepage_heading1">
              Welcome to <span className="Homepage_span">Hypersage</span>
            </h3>
            <h2 className="Homepage_heading2">Your Digital Growth Partner</h2>
            <p className="Homepage_paragraph1">
              At Hypersage, we redefine success in the digital realm. As a
              premier provider of comprehensive software solutions and digital
              marketing services, we empower businesses to thrive in the
              ever-evolving landscape of the online world.
            </p>
            <button className="Homepage_button" onClick={toggleForm}>Join us</button>
          </div>
        </div>
      </div>
      <Clienttrust />
      <Slide />
      <OurServicePage />
      <Methodology />
      <PricingHome />
      <AlllogoPage />
      <AlllogoPage2 />
      <AlllogoPage3 />
      <TestimonialPage />
      {/* <ManagementPage /> */}
      {/* <TeamSlide /> */}
      <FaqAccordion />
      <Page />
      {showForm && (
        <div className="contact-form-container">
          <div className="contact-form">
            <button className="close-btn" onClick={toggleForm}>
              Close
            </button>
            <h2>Contact Us</h2>
            <form 
           
              id="newcontactpage_form"
              >
              <label htmlFor="Name">Name *</label>
              <input type="text" id="name" name="name" value={memoizedFormData.name} onChange={handleChange} required />

              <label htmlFor="contactNumber">Contact Number *</label>
              <input type="number" id="contactNumber" name="number" value={memoizedFormData.number} onChange={handleChange} required />

              <label htmlFor="Email I'D">Email ID *</label>
              <input type="email" id="email" name="email" value={memoizedFormData.email} onChange={handleChange} required />

              <div class="form-group">
                <label for="textarea">Message</label>
                <textarea required=""  value={memoizedFormData.message}  onChange={handleChange} style={{color:"#000"}} cols="50" rows="16" id="textarea" name="message">          </textarea>
              </div>

              <button type="submit" id="submitButton" onClick={handleSubmit} className="btn">Submit</button>
            </form>
          </div>
        </div>
      )}

    </>
  );
};

export default Home;
