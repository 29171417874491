import React from "react";
import "../OurService Page/Our Service.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Servicelogo1 from "../../Assets/servicelogo1.png";

import Servicelogo2 from "../../Assets/servicelogo2.png";
import Servicelogo3 from "../../Assets/sservicelogo3.png";
import Servicelogo4 from "../../Assets/servicelogo4.png";
import Servicelogo5 from "../../Assets/servicelogo5.png";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const OurServicePage = () => {
  return (
    <>
      <div className="OurServicePage_main_body">
        <div className="servicepage_top_body">
          <h1 className="servicepage_heading1">
            Our <span className="servicepage-span">Services</span>
          </h1>
          <p className="servicepage_paragraph1">
          Explore cutting-edge software solutions tailored to elevate your business at every level. Our services encompass innovative applications, robust system integration, and strategic technology consulting to empower your digital success.


          </p>
        </div>
        <div className="OurServicePage_body">
          <div className="servicepage_container">
            <div className="servicepage_wrapper">
              <div className="servicepage_service">
                {/* First card */}
                <div className="servicepage_cards">
                  <div className="serviceapage_card">
                    <img
                      src={Servicelogo1}
                      alt=""
                      className="servicepagea_cardy_imageall1"
                    />
                    <h4 className="servicepage_heading2">
                      Strategic Bulk SMS Services
                    </h4>
                    <p className="serviceapge_paragraph2">
                    Propel Your outreach Efforts with our High-Performance Messaging Solutions Ensuring Swift and Effective Communication at Scale.
                    </p>
                  </div>
                  {/* 2nd */}
                  <div className="serviceapage_card">
                    <FontAwesomeIcon
                      icon={faWhatsapp}
                      className="servicepage_card_icon1"
                    />
                    <h4 className="servicepage_heading3">
                      Bulk WhatsApp Messaging
                    </h4>
                    <p className="serviceapge_paragraph3">
                      Bulk WhatsApp Messaging Enhance Connectivity and
                      Engagement with our Effortless and Scalable Communication
                      Platform, Tailored for Your Business Success.
                    </p>
                  </div>
                  {/* 3rd */}
                  <div className="serviceapage_card">
                    <img
                      src={Servicelogo5}
                      alt=""
                      className="servicepage_card_icon2"
                    />
                    <h4 className="servicepage_heading4">
                      Innovative Software Solutions
                    </h4>
                    <p className="serviceapge_paragraph4">
                      Innovative Software Solutions Elevate Your Digital
                      Presence with Our Cutting-Edge Technologies and Customized
                      Applications.
                    </p>
                  </div>
                  {/* 4th */}
                  <div className="serviceapage_card">
                    <img
                      src={Servicelogo4}
                      alt=""
                      className="servicepage_cardY-icon2"
                    />
                    <h4 className="servicepage_heading5">
                      Strategic Brand Promotions
                    </h4>
                    <p className="serviceapge_paragraph5">
                      Ignite Recognition and Boost Engagement with our Tailored
                      Marketing Solutions, Driving Success for Your Brand on
                      Every Front.
                    </p>
                  </div>
                  {/* 5ht  */}
                  <div className="serviceapage_card">
                    <img
                      src={Servicelogo3}
                      alt=""
                      className="servicepage_card_iconsms"
                    />
                    <h4 className="servicepage_heading6">
                      Strategic Digital Marketing Solutions
                    </h4>
                    <p className="serviceapge_paragraph6">
                      Elevate Your Online Presence with our Comprehensive and
                      Targeted Approach, Driving Growth and Maximizing Impact in
                      the Digital Landscape.
                    </p>
                  </div>
                  {/* 6th */}
                  <div className="serviceapage_card">
                    <img
                      src={Servicelogo2}
                      alt=""
                      className="servicepage_card_iconht"
                    />
                    <h4 className="servicepage_heading7">
                      Seamless API Integration Services
                    </h4>
                    <p className="serviceapge_paragraph7">
                      Elevate Your Systems with Efficient and Scalable
                      Solutions, Streamlining Connectivity and Enhancing
                      Operational Effectiveness.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default OurServicePage;
