import React from 'react'
import './Page.css'
import screen from '../../Assets/sssh.png'
const Page = () => {
  return (
    <div className='scr'>
      {/* <img src={screen} alt="" srcset="" /> */}
    </div>
  )
}

export default Page
