import React from "react";
import "../Digitalfile page/AlllogoPage3.css";
import NewLogo3 from "../../Assets/new logo3.png";
const AlllogoPage3 = () => {
  return (
    <>
      <div id="AlllogoPage3_main_body">
        <h2 id="Alllogopage3_heading1">Integrated Growth</h2>
        <div id="AlllogoPage3_body3">
          <div id="AlllogoPage3_row3">
            <div id="AlllogoPage3_imgwrapper3">
              <div id="AlllogoPage3_card3">
                {/* <img src={Intergratedlogo} alt="" id="AlllogoPage3_image4" /> */}
                <img src={NewLogo3} alt="" id="NewLogoImage3Id" />
              </div>
            </div>
            {/* content */}
            <div id="Alllogopage3_contentWrapper3">
              <div id="AlllogoPage3_content3">
                <p id="Alllogopage3_paragraph3">
                At Hypersage, we go beyond individual solutions to provide an integrated approach for your business growth. Seamlessly blending software solutions, digital marketing mastery, and powerful SMS solutions, we create a holistic strategy to drive unparalleled results. Join us on the journey to transform your business digitally and witness the synergy of integrated growth that sets you apart in the competitive landscape.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AlllogoPage3;
