import React from "react";
import "../Methodology/Methodology.css";
import v from "../../Assets/Vector.png";
import vo from "../../Assets/Vector__1.png";
import grt from "../../Assets/Group_29.png";
import thrt from "../../Assets/Group_30.png";
import vtw from "../../Assets/Vector__2.png";
import vs from "../../Assets/Vector 6.svg";
import Method from "../../Assets/method.png";
import miniLap from "../../Assets/methodology.png";
import vth from "../../Assets/Vector 3.svg";
const Methodology = () => {
  return (
    <div className="metholodogy_section">
      <div className="heads">
        <h1 className="methology_heading">
          Hypersage Methodology:
          <span> Proven Strategies for Supercharging Your Success</span>
        </h1>
        <p className="metholody_parargraph1">
          Methodology is a successful strategy for accomplishing goals through
          structured planning and execution, with a track record of proven
          results.
        </p>
      </div>
    
      <div className="min-lap">
     
<center>

        <img className="min_img" src={miniLap} style={{height:"400px",width:"80%"}}></img>
</center>

    
      </div>

      <div className="m_mob">
      <center>
     
        <img className="m_img" src={Method} style={{height:"700px",width:"220px"}}></img>
        </center>
        </div>

    </div>
  );
};

export default Methodology;
