import React, { useState } from "react";
import "../Accordianpage/Accordian.css"; // Assuming you've imported your CSS file

const FaqAccordion = () => {
  const faqs = [
    {
      question: "What type of custom AI solutions does your company offer?",
      answer:
        "Hypersage Technology specializes in providing a wide range of custom Al solutions, including Al Development Services, NLP Services, and Machine Learning Services. Our expertise extends to ChatGPT integration services, offering cutting-edge Al development solutions tailored to your business needs.",
    },
    {
      question: "Do you offer post-development support?",
      answer:
"Hypersage Technology offers comprehensive post-development support for all our services, including Al Development, UI/UX Design, and Custom Software Development. We ensure your solutions run smoothly and efficiently after the initial: development phase ."    },
    {
      question: "Can AI services help us make better decisions?",
      answer:
        "Absolutely! Our Al services, such as Machine Learning Development Services, can significantly enhance decision-making processes for your business. By analyzing data and generating insights, our Al solutions empower you to make informed, data- driven decisions.",
    },
    {
      question:
        "How do I choose the right AI consulting provider for my business?",
      answer:
        "Choosing the right AI consulting provider is crucial. Consider factors such as their expertise in Al Development, Al software development company reputation, and their ability to provide services like Custom Mobile App Development, Web Development, and Digital Marketing Solutions. Make sure they align with your specific needs .",
    },
    {
      question:
        "Why choose Hypersage Technology as your AI development company?",
      answer:
        "Hypersage Technology stands out as your preferred Al development company due to our extensive experience in Al Development Services, commitment to excellence, and a dedicated team of developers specializing in Custom Software Development, DevOps Services, and more. We deliver innovative Al solutions that drive results.",
    },
    {
      question: "What type of services does Hypersage Technology offer?",
      answer:
        "Hypersage Technology offers a comprehensive suite of services, including Al Development Services, UI/UX Design Services, Custom Software Development Services, DevOps Services, Custom Mobile App Development Services, Web Development Services, Digital Marketing Solutions, NLP Services, ChatGPT integration services and Machine Learning Services. We cover all your business needs under one roof.",
    },
    {
      question: "Which software is best for mobile app development?",
      answer:
        "The choice of the best software for mobile app development depends on your project requirements. Please consult our experts for guidance on selecting the most suitable tools and technologies for your Custom Mobile App Development project.",
    },
    {
      question: "Why Are UI UX Services Important?",
      answer:
        "The choice of the best software for mobile app development depends on your project requirements. Please consult our experts for guidance on selecting the most suitable tools and technologies for your Custom Mobile App Development project.",
    },
    {
      question: "Would My Business Benefit from Digital Marketing Services?",
      answer:
        "Your business can benefit significantly from our Digital Marketing Solutions. We offer services to boost your online presence, drive traffic, and convert leads into customers, ultimately growing your businesss visibility and revenue.",
    },
    {
      question: "Will Hypersage Technology do custom web development work?",
      answer:
        "Absolutely, Hypersage Technology offers Custom Web Development Services to create tailor-made websites that align with your business goals and brand identity. Our team leverages the latest technologies to build aynarnic and responsive web solutions.",
    },
    {
      question: "What are the benefits of DevOps implementation?",
      answer:
        "Implementing DevOps brings numerous benefits, including faster software development cycles, improved collaboration between development and operations teams, and increased system stability, it streamlines processes and enhances overall efficiency.",
    },
    {
      question: "How much does it cost to build custom software?",
      answer:
        "The cost of building custom software varies based on project complexity and features. Hypersage Technology provides competitive pricing for Custom Software Development Services, ensuring you receive a cost-effective solution tailored to your needs. Contact us for a personalized quote.",
    },
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <section>
      <div className="faq-accordion">
        <h1 className="ques">Frequently asked Questions-</h1>
        {faqs.map((faq, index) => (
          <div className="faq-item" key={index}>
            <div
              className={`faq-question ${
                activeIndex === index ? "active" : ""
              }`}
              onClick={() => toggleAccordion(index)}
            >
              <h3>{faq.question}</h3>
              <span>{activeIndex === index ? "-" : "+"}</span>
            </div>
            <div
              className={`faq-answer ${activeIndex === index ? "active" : ""}`}
            >
              <p>{faq.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default FaqAccordion;
