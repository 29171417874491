import React from "react";
import { useState } from "react";
import "../NewContactpage/NewContactPage.css";
import { Link } from "react-router-dom";
import Modal from "react-modal";
const NewContactPage = () => {
  const [selectedOption, setSelectedOption] = useState("");

  const handleChange = (e) => {
    setSelectedOption(e.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const apiEndpoint = 'https://script.google.com/macros/s/AKfycby2hONQO2oXvbiYYl7G2-0HM1Vqa91xt-2j7mIHlomWAW_xOYrkt4veSAatsq78bYaR/exec';


    const formData = new FormData(e.target);

    try {
      const response = await fetch(apiEndpoint, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        console.log("Form submitted successfully!");
      } else {
        console.error("Form submission failed!");
      }
    } catch (error) {
      console.error("Error during form submission:", error);
    }
  };


  return (
    <>
      <div id="NewContactPage_main_body">
        <div id="newcontactpage_container">
          <h2 id="newcontactpage_card_heading">Contact Us</h2>
          <p id="newcontactpage_card_paragraph">
            Hypersage Technology PVT LTD, AIC-DSU INNOVATION FOUNDATION, 48/1,
            Block B, 3rd floor, Dayananda Sagar University, Kudlu Gate, Hosur
            Road, Bangalore 560068.
          </p>
          <Link id="newcontactpage_link_button">info@hypersagetech.com</Link>
          {/* form Start */}
          <form
            action="your-google-sheet-submit-url"
            method="POST"
            id="newcontactpage_form"
            name="submit-to-google-sheet"
            onSubmit={handleSubmit}
          >
            <div id="newcontactpage_form_group">
              <label htmlFor="Name" id="newcontactpage_label">
                Name{" "}
                <span id="newcontactpage_span" style={{ color: "#C00" }}>
                  *
                </span>{" "}
                <br />
                <input
                  type="text"
                  // value={formData.Name}
                
                  id="newcontactpage_form_inputs"
                  name="Name"
                  required
                  onChange={handleChange}
                />
              </label>
              <label htmlFor="EmailID" id="newcontactpage-label2">
                Email I'D{" "}
                <span id="newcontactpage_span" style={{ color: "#C00" }}>
                  *
                </span>{" "}
                <br />
                <input
                  // value={formData.Email}
                  type="email"
                 
                  id="newcontactpage_form_inputs"
                  name="Email I'D"
                  required
                  onChange={handleChange}
                />
              </label>
              <label htmlFor="PhoneNumber" id="newcontactpage_label3">
                Phone Number{" "}
                <span id="newcontactpage_span" style={{ color: "#C00" }}>
                  *
                </span>{" "}
                <br />
                <input
                  // value={formData.PhoneNumber}
                  type="text"
                 
                  id="newcontactpage_form_inputs"
                  name="Phone Number"
                  required
                  onChange={handleChange}
                />
              </label>
              <label htmlFor="SelectOption" id="newcontactpage_label4">
                Select an option
                <span id="newcontactpage_span" style={{ color: "#C00" }}>
                  *
                </span>{" "}
                <br />
                <select
                  // value={formData.SelectOption}
                  name="Select an Option"
                  id="newcontactuspage_select"
                  required
                  onChange={handleChange}
                >
                  <option value="" disabled selected>
                    
                  </option>
                  <option value="Software Development">
                    Software Solution
                  </option>
                  <option value="Bulk WhatsApp Message">
                    Bulk WhatsApp Message
                  </option>
                  <option value="Brand Promotion">
                    Brand Promotion
                  </option>
                </select>
              </label>
            </div>
            {/*  */}
            <div id="newcontactpage_under_button">
              <button type="submit" id="newcontactpage_form_button">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default NewContactPage;
