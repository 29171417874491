import React,{useState,useMemo} from "react";
import "../Whatsapppage/Whatsapp.css";
import whatsapplogo1 from "../../Assets/100t0 9999.png";
import whatsapplogo2 from "../../Assets/10000.png";
import whatsapplogo3 from "../../Assets/30000.png";
import whatsapplogo4 from "../../Assets/80000.png";
import whatsapplogo5 from "../../Assets/w24.png";
import whatsapplogo6 from "../../Assets/w9.png";
import whatsapplogo7 from "../../Assets/w25.png";
import whatsapplogo8 from "../../Assets/w14.png";

import { Link } from "react-router-dom";
import LetsBuildPage from "../LetsBuildPage/LetsBuildPage";

const Whatsapp = () => {
  const [formData, setFormData] = useState({
    name: '',
    number: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    showPleaseWaitMessage();

    const formDataToSubmit = new FormData();
    formDataToSubmit.append('name', formData.name);
    formDataToSubmit.append('number', formData.number);
    formDataToSubmit.append('email', formData.email);
    formDataToSubmit.append('message', formData.message);

    fetch('https://script.google.com/macros/s/AKfycbxX0_gWnHrh8AAb9v-o0rV9aV-FAJawwUq2rCNXnW5WcyVs3Qlg62ltebVgl0M7i4zE-Q/exec', {
      method: 'POST',
      body: formDataToSubmit
    })
    .then(response => response.text())
    .then(data => {
      console.log(data); // Handle response from server if needed
      alert('Form submitted successfully!');
      setFormData({ name: '', number: '', message: '',email:"" });
     
    })
    .catch(error => {
      console.error('Error:', error);
      alert('An error occurred while submitting the form.');
    })
    .finally(() => {
      hidePleaseWaitMessage();
    });
  };

  const showPleaseWaitMessage = () => {
    const submitButton = document.getElementById('submitButton');
    submitButton.innerText = 'Please wait...';
    submitButton.disabled = true;
  };

  const hidePleaseWaitMessage = () => {
    const submitButton = document.getElementById('submitButton');
    submitButton.innerText = 'Submit';
    submitButton.disabled = false;
  };

  const memoizedFormData = useMemo(() => ({
    name: formData.name,
    number: formData.number,
    email: formData.email,
    message: formData.message
  }), [formData]);
  return (
    <>
      <div className="Whatsapppage_main_body">
        {/* backgroundimagesection */}
        <div id="whatsapppage_bgImage">
          <div id="whatsapppage_content">
            <h2 id="whatsapppage_heading1">Bulk Whatsapp</h2>
            <p id="whatsapppage_paragraph1">
              Connect Seamlessly, Communicate Effectively with Hypersage
              Messaging
            </p>
          </div>
        </div>
        {/* paragraph section */}
        <div id="whatsapppage_paragraph-all-body">
        <p id="whatsapppage_paragraphs1">
  Engage your audience with precision through our Bulk service. Whether it's promotional offers,
  event updates, or critical alerts, our platform ensures that your messages
  reach the right audience at the right time. Benefit from
  high-speed delivery and comprehensive analytics to
  refine your strategy.
</p>
<p id="whatsapppage_paragraphs2">
  Forge stronger connections using the world's favourite messaging platform – WhatsApp. 
  With Hypersage, you can send messages using your own number, adding a personal touch to your communication. 
  Build trust and credibility by connecting with your audience on a platform they use daily.
</p>

<p id="whatsapppage_paragraphs3">
  Experience the synergy of Bulk SMS and WhatsApp Messaging with Hypersage. 
  Connect, engage, and drive results with our powerful messaging solutions. 
  Whether you're a business reaching out to customers or an organization keeping your community informed, we've got you covered.
</p>
          <h3 id="whatsapppage_heading4">
            Hypersage Messaging-Where Every Message Matters.
          </h3>
        </div>
        {/* card section */}
        <div id="whatsapppage_bulk_heading2">Bulk Whatsapp</div>
        <div id="whatsapppage_card_container">
          <div id="whatsapppage_card">
            <img src={whatsapplogo1} alt="" id="whatsapppage_image1" />
            <div id="whatsapppage_card_content">
              <Link id="whatsapppage_button">Buy Now</Link>
            </div>
          </div>
          {/* 2nd */}
          <div id="whatsapppage_card">
            <img src={whatsapplogo2} alt="" id="whatsapppage_image1" />
            <div id="whatsapppage_card_content">
              <Link id="whatsapppage_button">Buy Now</Link>
            </div>
          </div>
          {/* 3rd */}
          <div id="whatsapppage_card">
            <img src={whatsapplogo3} alt="" id="whatsapppage_image1" />
            <div id="whatsapppage_card_content">
              <Link id="whatsapppage_button">Buy Now</Link>
            </div>
          </div>
          {/* 4th */}
          <div id="whatsapppage_card">
            <img src={whatsapplogo4} alt="" id="whatsapppage_image1" />
            <div id="whatsapppage_card_content">
              <Link id="whatsapppage_button">Buy Now</Link>
            </div>
          </div>
        </div>
        {/* 2dn whatsapp double */}
        <div id="whatsapppage_heading3">Single Number Whatsapp</div>
        <div id="whatsapppage_card_container">
          <div id="whatsapppage_card">
            <img src={whatsapplogo5} alt="" id="whatsapppage_image1" />
            <div id="whatsapppage_card_content">
              <Link id="whatsapppage_button">Buy Now</Link>
            </div>
          </div>
          {/* 2nd */}
          <div id="whatsapppage_card">
            <img src={whatsapplogo6} alt="" id="whatsapppage_image1" />
            <div id="whatsapppage_card_content">
              <Link id="whatsapppage_button">Buy Now</Link>
            </div>
          </div>
          {/* 3rd */}
          <div id="whatsapppage_card">
            <img src={whatsapplogo7} alt="" id="whatsapppage_image1" />
            <div id="whatsapppage_card_content">
              <Link id="whatsapppage_button">Buy Now</Link>
            </div>
          </div>
          {/* 4th */}
          <div id="whatsapppage_card">
            <img src={whatsapplogo8} alt="" id="whatsapppage_image1" />
            <div id="whatsapppage_card_content">
              <Link id="whatsapppage_button">Buy Now</Link>
            </div>
          </div>
        </div>
        {/* contact */}
        <div id="NSMSPAGE_CONTACT_BODY">
          <div id="NSMSPAGE_FORM-CONTAINER">
            <div id="NSMSPAGE_FORM_CONTACT_INFO">
              <div id="NSMSPAGE_FORM-BOX">
                <div id="NSMSPAGE_FORM_TEXT">
                  <h2 id="NSMSPAGE_FORM_TEXT2">Let's Build</h2>
                  <h2 id="NSMAPHE_FORM_TEXT3">
                    <span id="NSMSPAGE_SPAN_FORM">Future</span> Together
                  </h2>
                  <p id="NSMSPAGE_PARAGRAPH_ONE">
                    Get In Touch; Together,Let's Shape a Prosperous Future.
                  </p>
                </div>
              </div>
            </div>
            {/* form */}
            <div id="NSMSPAGE_CONTACT_FORM_BODY">
              <div id="NSMSPAGE_ALL_FORM">
              <form onSubmit={handleSubmit} id="NSMSPAGE_FORM">
      <div id="NSMSPAGE_FORM_TITLE">Need Help?</div>
      <div id="NSMSPAGE_FORM_HALF">
        <div id="NSMSPAGE_ITEMS_FORM">
          <input
            type="text"
            name="name"
            id="NSMSPAGE_INPUT_BOX"
            value={memoizedFormData.name}
            onChange={handleChange}
            placeholder="Name"
          />
        </div>
        <div id="NSMSPAGE_ITEMS_FORM">
          <input
            type="number"
            name="number"
            id="NSMSPAGE_INPUT_BOX_Two"
            value={memoizedFormData.number}
            onChange={handleChange}
            placeholder="Number"
          />
        </div>
      </div>
      <div id="NSMSPAGE_ITEMS_FORM">
        <input
          type="email"
          name="email"
          id="NSMSPAGE_INPUT_BOX_Two"
          value={memoizedFormData.email}
          onChange={handleChange}
          placeholder="Email ID"
        />
      </div>
      <div id="NSMSPAGE_Form_Full">
        <textarea
          name="message"
          style={{color:"#000"}}
          value={memoizedFormData.message}
          onChange={handleChange}
          placeholder="Your Message"
        ></textarea>
      </div>
      <div id="NSMSPAGE_Form_Action">
      <button type="submit" id="submitButton" onClick={handleSubmit} className="btn">Submit</button>
      </div>
    </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Whatsapp;
