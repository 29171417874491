import { Route, Routes } from "react-router-dom";
import "./App.css";
import AboutComponent from "./Components/AboutPage/About";
import Digital from "./Components/Digital Markting/Digital";
import Footer from "./Components/Footerpage/Footer";
import Home from "./Components/Home/Home";
import Navbar from "./Components/Navbar/Navbar";
import Promotion from "./Components/Brand Promotion/Prmotion";
import PrivacyPage from "./Components/Privacy/Privacy";
import Smspage from "./Components/SMSSERVICE PAGE/Smspage";
import SoftwereSolution from "./Components/SoftwereSolution/SoftwereSolution";
import TermsPage from "./Components/TermsPage/Terms";
// import Bulkwhastapp from "./Components/BulkWhatsapp page/Bulkwhastapp";
import Newcareer from "./Components/Newcarrerpage/Newcareer";
// import Contact from "./Components/Contact/Contact";
import Whatsapp from "./Components/Whatsapppage/Whatsapp";
import NewCareerPage from "./Components/Newcarrerpage/Newcareer";
import Newsroompage from "./Components/Newsroompage/Newnewsrrom";
import NewContactPage from "./Components/NewContactpage/NewContactPage";
import NSignInPage from "./Components/NewSigninPage/NSignInPage"
import Gallery from "./Components/Gallery/gallery";





// import Sms from "./Components/SmsService/Sms";

function App() {

  return (
    <div className="App">
      <Navbar/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutComponent />} />
        <Route path="/sms-service" element={<Smspage />} />
        <Route path="/contact" element={<NewContactPage />} />
        {/* <Route path="/bulk-whatsapp" element={<Bulkwhastapp />} /> */}
        <Route path="/bulk-whatsapp" element={<Whatsapp />} />
        <Route path="/software-solution" element={<SoftwereSolution />} />
        <Route path="/brand-promotion" element={<Promotion />} />
        <Route path="/digital-marketing" element={<Digital />} />
        <Route path="privaypage" element={<PrivacyPage />} />
        <Route path="/carrer" element={<NewCareerPage/>} />
        <Route path="/newsroom" element={<Newsroompage/>} />
        <Route path="termspage" element={<TermsPage />} />
        <Route path="/signin" element={<NSignInPage />} />
        <Route path="/gallery" element={<Gallery />} />

      </Routes>
      <Footer />
      {/* <SoftwereSolution /> */}
    </div>
  );
}

export default App;
