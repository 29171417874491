import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { IoMdArrowDropdown } from "react-icons/io";
import { FaArrowRight } from "react-icons/fa";

import Dialog from '@mui/material/Dialog';
import "../Navbar/Navbar.css";
import p from "../../Assets/Group 1log.png";
import Modal from "react-modal";
import "../NewContactpage/NewContactPage.css";
import MenuIcon from '@mui/icons-material/Menu';

import { Tick } from 'react-crude-animated-tick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Button, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';


const Navbar = () => {
  useEffect(() => {
    const logo = document.querySelector(".logo");
    logo.classList.add("slideInTop");
  }, []);

  const [showMenu, setshowMenu] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showSignInForm, setShowSignInForm] = useState(false);
  const location = useLocation();
  const dropdownRef = useRef(null);
  // const [modal, setModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const opens = Boolean(anchorEl);
  const handleClicks = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloses = () => {
    setAnchorEl(null);
    setshowMenu(false)
  };
  const closeModals = () => {
    setModal(false);
  }
  const customStyles2 = {
    content: {
      top: "45%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "600px",
      borderRadius: "15px",
      height: "300px",
      zIndex: "200",
    },
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    setSelectedOption(e.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const apiEndpoint = 'https://script.google.com/macros/s/AKfycby2hONQO2oXvbiYYl7G2-0HM1Vqa91xt-2j7mIHlomWAW_xOYrkt4veSAatsq78bYaR/exec';


    const formData = new FormData(e.target);

    try {
      const response = await fetch(apiEndpoint, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        console.log("Form submitted successfully!");
      } else {
        console.error("Form submission failed!");
      }
    } catch (error) {
      console.error("Error during form submission:", error);
    }
  };
  const closeModal = () => {
    setModal(false);
  }


  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleScroll = () => {
    closeDropdown();
  };
  const closeDropdown = () => {
    setShowDropdown(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };

    if (showDropdown) {
      // document.addEventListener('mousedown', handleClickOutside);
      window.addEventListener('scroll', handleScroll);
    } else {
      // document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('scroll', handleScroll);
    }

    return () => {
      // document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [showDropdown]);

  // const toggleDropdown = () => {
  //   setShowDropdown(true);
  // };
  const handleClick = () => {
    setshowMenu(false);
    setShowSignInForm(!showSignInForm);
  };

  const toggleSignInForm = () => {
    setShowSignInForm(!showSignInForm);
  };
  const toggleForm = () => {
    setShowSignInForm(!showSignInForm);
  };

  return (
    <nav className="navbar">

      <img src={p} alt="Logo" className="logo" />

      {/* Desktop Menu */}
      <div className="desktopMenu">
        <Link
          to="/"
          className={`desktopMenuListItem ${location.pathname === '/' && 'active'}`}
          onClick={closeModal}
        >
          Home
        </Link>
        <Link
          to="/about"
          className={`desktopMenuListItem ${location.pathname === '/about' && 'active'}`}
          onClick={closeModal}
        >
          About Us
        </Link>
        <Link
          // to="/contact"
          className="desktopMenuListItem"
          onClick={handleClickOpen}
        >
          Contact Us
        </Link>
        <div className="dropdown">
        <Link
          // to="/about"
          className={`desktopMenuListItem ${location.pathname === '/pricing' && 'active'}`}
          onClick={handleClicks}
        >
       Pricing <IoMdArrowDropdown />

      </Link>
          
         
          <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={opens}
        onClose={handleCloses}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      
        style={{marginTop:20}}
      >
        <MenuItem onClick={handleCloses}> <Link to="/sms-service#top" className="dropdownItem" onClick={closeModal}>
                <p><FaArrowRight className="arrow-icon" /> <span>SMS Service</span></p>
              </Link></MenuItem>
        <MenuItem onClick={handleCloses}> <Link to="/bulk-whatsapp" className="dropdownItem" onClick={closeModal}>
                <FaArrowRight className="arrow-icon" /><span> Bulk WhatsApp</span>
              </Link></MenuItem>
        <MenuItem onClick={handleCloses}> <Link to="/software-solution" className="dropdownItem" onClick={closeModal}>
                <FaArrowRight className="arrow-icon" /><span>Software Solution</span>
              </Link></MenuItem>
        <MenuItem onClick={handleCloses}> <Link to="/brand-promotion" className="dropdownItem" onClick={closeModal}>
                <FaArrowRight className="arrow-icon" /><span>Brand Promotion</span>
              </Link></MenuItem>
        <MenuItem onClick={handleCloses}><Link to="/digital-marketing" className="dropdownItem" onClick={closeModal}>
                <FaArrowRight className="arrow-icon" /><span>Digital Marketing</span>
              </Link></MenuItem>
      </Menu>
          {/* {showDropdown && (
            <div className="dropdown-content">
             
             
             
             
              
            </div>
          )} */}
        </div>
        <Link to={"/signin"}>
        <button className="sign" 
        // onClick={toggleSignInForm}
        >
          Sign in
        </button>
        </Link>
      </div>
      {showSignInForm && (
        <div className="sign-in-form">
          {/* Your sign-in form components go here */}
          <button className="close-btn" onClick={toggleForm}>
            Close
          </button>
          <form>
            <label htmlFor="username">Username:</label>
            <input type="text" id="username" />
            <label htmlFor="password">Password:</label>
            <input type="password" id="password" />
            <button type="submit">Sign In</button>
          </form>
        </div>
      )}

      {/* Mobile Menu */}



      {showMenu ? <CloseIcon sx={{ fontSize: 40 }} className="mobMenu" onClick={() => setshowMenu(!showMenu)} /> :
        <MenuIcon sx={{ fontSize: 40 }} className="mobMenu"
          onClick={() => setshowMenu(!showMenu)} />




      }



      <div className="navMenu" style={{ display: showMenu ? "flex" : "none" }}>
        <Link to="/" className="listItem" onClick={() => setshowMenu(false)}>
          Home
        </Link>
        <Link
          to="/about"
          className="listItem"
          onClick={() => setshowMenu(false)}
        >
          About Us
        </Link>
        <Link
          // to="contact"
          className="listItem"
          onClick={handleClickOpen}
        >
          Contact us
        </Link>
        <Link to={"/signin"}
        
          className="listItem"
          // onClick={handleClick}
        >
          Sign in
        </Link>
        {showSignInForm && (
          <div className="sign-in-form">
            {/* Your sign-in form components go here */}
            <form>
              <label htmlFor="username">Username:</label>
              <input type="text" id="username" />
              <label htmlFor="password">Password:</label>
              <input type="password" id="password" />
              <button type="submit">Sign In</button>
            </form>
          </div>
        )}
        <div className="dropdown">
          <Link to="" className="listItem"
         
         onClick={handleClicks}
         >
        Pricing <IoMdArrowDropdown />
 
       </Link>
           
          
           <Menu
         id="basic-menu"
         anchorEl={anchorEl}
         open={opens}
         onClose={handleCloses}
         MenuListProps={{
           'aria-labelledby': 'basic-button',
         }}
       
         style={{marginTop:20}}
       >
         <MenuItem onClick={handleCloses}> <Link to="/sms-service#top" className="dropdownItem" onClick={closeModal}>
                 <p><FaArrowRight className="arrow-icon" /> <span>SMS Service</span></p>
               </Link></MenuItem>
         <MenuItem onClick={handleCloses}> <Link to="/bulk-whatsapp" className="dropdownItem" onClick={closeModal}>
                 <FaArrowRight className="arrow-icon" /><span> Bulk WhatsApp</span>
               </Link></MenuItem>
         <MenuItem onClick={handleCloses}> <Link to="/software-solution" className="dropdownItem" onClick={closeModal}>
                 <FaArrowRight className="arrow-icon" /><span>Software Solution</span>
               </Link></MenuItem>
         <MenuItem onClick={handleCloses}> <Link to="/brand-promotion" className="dropdownItem" onClick={closeModal}>
                 <FaArrowRight className="arrow-icon" /><span>Brand Promotion</span>
               </Link></MenuItem>
         <MenuItem onClick={handleCloses}><Link to="/digital-marketing" className="dropdownItem" onClick={closeModal}>
                 <FaArrowRight className="arrow-icon" /><span>Digital Marketing</span>
               </Link></MenuItem>
       </Menu>
        </div>
      </div>
      <Dialog
        onClose={handleClose}
        // aria-labelledby="customized-dialog-title"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        open={open}
        sx={{
          backdropFilter: "blur(0px) sepia(1%)",
        }}
        // 👇 Props passed to Paper (modal content)
        PaperProps={{ sx: { borderRadius: "50px" } }}

      >



        <div className="contact_us_card" style={{


        }}>
          <div  id="newcontactpage_container">
            <IconButton className="close-btn" onClick={handleClose}>
              <CloseIcon style={{color:"white",fontSize:"30px"}} />
            </IconButton>
          {/* <button className="close-btn" onClick={handleClose}>
              Close
            </button> */}
            <h2 id="newcontactpage_card_heading">Contact Us</h2>
           
           
            {/* form Start */}
            <form
              action="your-google-sheet-submit-url"
              method="POST"
              id="newcontactpage_form"
              name="submit-to-google-sheet"
              onSubmit={handleSubmit}
            >
              <div id="newcontactpage_form_group">
                <label htmlFor="Name" id="newcontactpage_label">
                  Name{" "}
                  <span id="newcontactpage_span" style={{ color: "#C00" }}>
                    *
                  </span>{" "}
                  <br />
                  <input
                    type="text"
                    // value={formData.Name}

                    id="newcontactpage_form_inputs"
                    name="Name"
                    required
                    onChange={handleChange}
                  />
                </label>
                <label htmlFor="EmailID" id="newcontactpage-label2">
                  Email I'D{" "}
                  <span id="newcontactpage_span" style={{ color: "#C00" }}>
                    *
                  </span>{" "}
                  <br />
                  <input
                    // value={formData.Email}
                    type="email"

                    id="newcontactpage_form_inputs"
                    name="Email I'D"
                    required
                    onChange={handleChange}
                  />
                </label>
                <label htmlFor="PhoneNumber" id="newcontactpage_label3">
                  Phone Number{" "}
                  <span id="newcontactpage_span" style={{ color: "#C00" }}>
                    *
                  </span>{" "}
                  <br />
                  <input
                    // value={formData.PhoneNumber}
                    type="text"

                    id="newcontactpage_form_inputs"
                    name="Phone Number"
                    required
                    onChange={handleChange}
                  />
                </label>
                
                <label htmlFor="SelectOption" id="newcontactpage_label4">
                 Message
                 
                  <br />
                  <textarea required="" cols="50" rows="16"  id="newcontactuspage_select" name="textarea">          </textarea>
               
                </label>
              </div>
              {/*  */}
              <div id="newcontactpage_under_button">
                <button onClick={() => {

                  setModal(true);
                  setOpen(false)
                }} type="submit" id="newcontactpage_form_button">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>


      </Dialog>
      <Modal isOpen={modal} onRequestClose={closeModals} style={customStyles2} contentLabel="My dialog">


        <FontAwesomeIcon
          icon={faTimes}
          style={{ fontSize: '20px', paddingLeft: '32rem', color: 'black' }}
          onClick={() => setModal(false)}
        />

        <div>
          <Tick size={130} />
        </div>



        <h3 className="black-text" style={{ textAlign: "center" }}>
          Submitted Sucessfully
        </h3>
        <h3 className="black-text" style={{ textAlign: "center" }}>
          We will Contact you soon..
        </h3>


      </Modal>
    </nav>
  );
};

export default Navbar;

