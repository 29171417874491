import React from "react";
import "../Newcarrerpage/Newcareer.css";
import { Link } from "react-router-dom";
import {useEffect} from 'react';
const NewCareerPage = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  return (
    <>
      <div id="NewCareerpage_main_body">
        <div id="newrcareerpage_hero_image">
          <div id="newrcareerpage_hero_content">
            <div id="newrcareerpage_hero_content_inner">
              <h2 id="newrcareerpage_heading1">
                Explore Exciting Career Opportunities at Hypersage
              </h2>
              <p id="newrcareerpage_paragraph1">
                We're on the lookout for passionate and talented individuals to
                join our team! At Hypersage, we believe in fostering innovation,
                collaboration, and continuous growth. Check out our current
                openings:
              </p>
            </div>
          </div>
        </div>
        {/* card section */}
        <div id="newcareerpage_section">
          <div id="newcareerpage_container">
            <div id="newcareerpage_card">
              <h3 id="newcareerpage_card_heading1">MERN Stack Developer</h3>
              <p id="newcareerpage_card_paragraph1">
                We are seeking a skilled MERN Stack Developer to contribute to
                the development of cutting-edge web applications.
              </p>
              <Link id="newcareerpage_link_button">Apply Now</Link>
            </div>
            {/* 2nd card */}
            <div id="newcareerpage_card">
              <h3 id="newcareerpage_card_heading1">Software Tester / QA Engineer </h3>
              <p id="newcareerpage_card_paragraph1">
              Join our Team to Evaluate software to ensure quality, identify defects, collaborate with developers, and maintain testing documentation.
              </p>
              <Link id="newcareerpage_link_button">Apply Now</Link>
            </div>
            {/* 3rd card */}
            <div id="newcareerpage_card">
              <h3 id="newcareerpage_card_heading1">
                Business Development Executive
              </h3>
              <p id="newcareerpage_card_paragraph3">
                Are you a go-getter with a passion for business growth? Join us
                as a Business Development Executive and be a key player in our
                expansion strategy.
              </p>
              <Link id="newcareerpage_link_button">Apply Now</Link>
            </div>
            {/* 4th */}
            <div id="newcareerpage_card">
              <h3 id="newcareerpage_card_heading1">
                Digital Marketing Executive
              </h3>
              <p id="newcareerpage_card_paragraph1">
                Shape our online presence and drive digital initiatives as a
                Digital Marketing Executive. Join us in creating impactful
                marketing campaigns.
              </p>
              <Link id="newcareerpage_link_button">Apply Now</Link>
            </div>
          </div>
        </div>
        {/* backgroundImage with text and Button */}
        <div id="newcareerpage_lastContent">
          <div id="newcareerpage_backgrundImage"></div>
          <div id="newcareerpage_last_content">
            <h2 id="newcareerpage_last-heading1">
              Ready to take the{" "}
              <span id="newcareerpage_last-span">next step</span> in your career
              journey? Apply now be part of the{" "}
              <span id="newcareerpage_last_span2">Hypersage family!</span>
            </h2>
            <button id="newcareerpage_last-button">Apply Now</button>
          </div>
        </div>
      </div>
    </>
  );
};
export default NewCareerPage;
