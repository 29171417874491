import React,{useEffect,useState,useMemo} from "react";
import "./Promotion.css";
import LetsBuildPage from "../LetsBuildPage/LetsBuildPage";

const Promotion = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  const [formData, setFormData] = useState({
    name: '',
    number: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    showPleaseWaitMessage();

    const formDataToSubmit = new FormData();
    formDataToSubmit.append('name', formData.name);
    formDataToSubmit.append('number', formData.number);
    formDataToSubmit.append('email', formData.email);
    formDataToSubmit.append('message', formData.message);

    fetch('https://script.google.com/macros/s/AKfycbxkkSSYrHTCCYu92hgXjlVZL4CJZWTiBkI45D16FwUhp2BjqB-WpttXk1VfFmWdt4Jqmg/exec', {
      method: 'POST',
      body: formDataToSubmit
    })
    .then(response => response.text())
    .then(data => {
      console.log(data); // Handle response from server if needed
      alert('Form submitted successfully!');
      setFormData({ name: '', number: '', message: '',email:"" });
     
    })
    .catch(error => {
      console.error('Error:', error);
      alert('An error occurred while submitting the form.');
    })
    .finally(() => {
      hidePleaseWaitMessage();
    });
  };

  const showPleaseWaitMessage = () => {
    const submitButton = document.getElementById('submitButton');
    submitButton.innerText = 'Please wait...';
    submitButton.disabled = true;
  };

  const hidePleaseWaitMessage = () => {
    const submitButton = document.getElementById('submitButton');
    submitButton.innerText = 'Submit';
    submitButton.disabled = false;
  };

  const memoizedFormData = useMemo(() => ({
    name: formData.name,
    number: formData.number,
    email: formData.email,
    message: formData.message
  }), [formData]);
  return (
    <>
      <div>
        <div className="promotion_BgImage"></div>
        <div className="Promotion_Content">
          <h2 className="Promotion_Heading1">Brand Promotion</h2>
          <p className="Promotion_Paragraph1">
            Unlock Your Business's Potential with Comprehensive Digital
            Solutions
          </p>
        </div>
        <div className="Promotion_Paragraph_all">
          <div className="Promotion_Paragraph">
            <p className="Prmotion_paragraph_2">
              In an era dominated by digital interactions,establishing a robust
              online presence is not just an option; it's a necessity.At
              Hypersage,we offer a suite of services that go beyond conventional
              marketing,propelling your business to new heights. From brand
              Promotion and targeted advertising to website building and
              comprehensive digital marketing strategies,we are your one-step
              solution for a thriving online enterprise.
            </p>
            <h2 className="Promotion_heading_1">Brand Promotion</h2>
            <p className="Promotion_Paragraph_3">
              Your brand is more than just a logo;it's an identity that speaks
              to your audience. Our brand promotion services focus on creating a
              compelling narrative for your business. We craft engaging
              stories,design eye-catching visuals,and implement strategic
              campaigns that resonate with your target market. Whether you're a
              startup looking to make waves or an established brand aiming for a
              refresh,our team ensures that your brand stands out in the digital
              landscape.
            </p>
            <h2 className="Promotion_heading-2">
              Ad Shooting and Digital Marketing
            </h2>
            <p className="Promotion_paragrapgh-4">
              Visual storytelling is a powerful tool in the digital age. Our ad
              shooting services are tailored to capture the essence of your
              brand and create visually stunning advertisements. From concept to
              execution,we ensure that your ads leave a lasting impression.Pair
              this with our digital marketing expertise,and you have a winning
              combination.We leverage social media platforms,search engines,and
              targeted ad placements to maximize your reach and drive meaningful
              engagement.
            </p>
            <h2 className="Promotion_heading_3">Website Building</h2>
            <p className="Promotion-paragrapg-5">
              Your website is often the first interaction a potential customer
              has with your business.Make it count with our professional website
              building services.Our team of skilled developers and designers
              create responsive,User-friendly websites that not only look great
              but also function seamlessly.Whether you need an e-commerce
              platform,a corporate website,or a portfolio,we have the expertise
              to bring your vision to life.
            </p>
            <h2 className="Promotion_heading-4">Taking Your Business Online</h2>
            <p className="Promotion_paragraph-6">
              Embrance the digital frontier with our comprehensive online
              solutions.From setting up e-commerce platforms to implementing
              secure payment gateways,we ensure that your business not only
              survives but thrives in the online space.Our strategies are
              data-driven,ensuring that every decision is backend by insights to
              maximize your online success.
            </p>
            <h2 className="Promotion_heading-5">Why Choose Hypersage?</h2>
            <p className="Promotion_paragrapg-7">
              Holistic Approach; We don't just focus on one aspect;we integrate
              brand promotion,ad shooting,website building,and digital marketing
              into a seamless strategy. Experienced Team;Our team comprises
              seasoned professionals who understand the nuances of the digital
              landscape.Custom Solutions:We tailor our services to meet the
              unique needs of your business,ensuring that you get the most
              effective solutions.
            </p>
            <h2 className="Promotion-heading_6">
              Ready to Transform Your Business?
            </h2>
            <p className="Promotion_paragraph-8">
              Take the leap into the digital realm with Hypersage.Whether you're
              a small business looking to make a mark or a large enterprise
              seeking a digital facelift,we have the expertise and passion to
              make it happen.Let's not just meet expectations;let's exceed them
              together.
            </p>
            <p className="Promotion_paragraph-9">
              Contact us today to kickstart your journey to digital success.Your
              brand deserves to be in the spotlight,and we're here to make it
              happen.
            </p>
            <h2 className="Promotion_heading-7">
              Hypersage - Empowering Your Digital Presence.
            </h2>
          </div>
        </div>
        {/* Lets build componets */}
        
        {/* footer components */}
      </div>
      <div id="NSMSPAGE_CONTACT_BODY">
          <div id="NSMSPAGE_FORM-CONTAINER">
            <div id="NSMSPAGE_FORM_CONTACT_INFO">
              <div id="NSMSPAGE_FORM-BOX">
                <div id="NSMSPAGE_FORM_TEXT">
                  <h2 id="NSMSPAGE_FORM_TEXT2">Let's Build</h2>
                  <h2 id="NSMAPHE_FORM_TEXT3">
                    <span id="NSMSPAGE_SPAN_FORM">Future</span> Together
                  </h2>
                  <p id="NSMSPAGE_PARAGRAPH_ONE">
                    Get In Touch; Together,Let's Shape a Prosperous Future.
                  </p>
                </div>
              </div>
            </div>
            {/* form */}
            <div id="NSMSPAGE_CONTACT_FORM_BODY">
              <div id="NSMSPAGE_ALL_FORM">
              <form onSubmit={handleSubmit} id="NSMSPAGE_FORM">
      <div id="NSMSPAGE_FORM_TITLE">Need Help?</div>
      <div id="NSMSPAGE_FORM_HALF">
        <div id="NSMSPAGE_ITEMS_FORM">
          <input
            type="text"
            name="name"
            id="NSMSPAGE_INPUT_BOX"
            value={memoizedFormData.name}
            onChange={handleChange}
            placeholder="Name"
          />
        </div>
        <div id="NSMSPAGE_ITEMS_FORM">
          <input
            type="number"
            name="number"
            id="NSMSPAGE_INPUT_BOX_Two"
            value={memoizedFormData.number}
            onChange={handleChange}
            placeholder="Number"
          />
        </div>
      </div>
      <div id="NSMSPAGE_ITEMS_FORM">
        <input
          type="email"
          name="email"
          id="NSMSPAGE_INPUT_BOX_Two"
          value={memoizedFormData.email}
          onChange={handleChange}
          placeholder="Email ID"
        />
      </div>
      <div id="NSMSPAGE_Form_Full">
        <textarea
          name="message"
          style={{color:"#000"}}
          value={memoizedFormData.message}
          onChange={handleChange}
          placeholder="Your Message"
        ></textarea>
      </div>
      <div id="NSMSPAGE_Form_Action">
      <button type="submit" id="submitButton" onClick={handleSubmit} className="btn">Submit</button>
      </div>
    </form>
              </div>
            </div>
          </div>
        </div>
    </>
  );
};
export default Promotion;
