import React, { useEffect, useState } from "react";
import "./Clienttrust.css";
const Clienttrust = () => {
  const [experience, setExperience] = useState(0);
  const [clients, setClients] = useState(0);
  const [employees, setEmployees] = useState(0);
  const [offices, setOffices] = useState(0);

  useEffect(() => {
      const finalExperience = 4;
      const finalClients = 870;
      const finalEmployees = 200;
      const finalOffices = 17;

      const increment = 1;
      const intervalTime = 5;

      const experienceInterval = setInterval(() => {
          if (experience < finalExperience) {
              setExperience((prev) => Math.min(prev + increment, finalExperience));
          }
      }, intervalTime);

      const clientsInterval = setInterval(() => {
          if (clients < finalClients) {
              setClients((prev) => Math.min(prev + increment, finalClients));
          }
      }, intervalTime);

      const employeesInterval = setInterval(() => {
          if (employees < finalEmployees) {
              setEmployees((prev) => Math.min(prev + increment, finalEmployees));
          }
      }, intervalTime);

      const officesInterval = setInterval(() => {
          if (offices < finalOffices) {
              setOffices((prev) => Math.min(prev + increment, finalOffices));
          }
      }, intervalTime);

      return () => {
          clearInterval(experienceInterval);
          clearInterval(clientsInterval);
          clearInterval(employeesInterval);
          clearInterval(officesInterval);
      };
  }, []);
  return (
    <>
      <div className="Clienttrust_body">
        <div className="clienttrust_content">
          <div className="clienttrust_top_body" style={{ color: "whitesmoke" }}>
            <p className="clienttrust_paragraph1">
            Embark on a transformative journey with Hypersage as we pledge to empower businesses and elevate success in the digital realm. Navigating beyond boundaries, our aim is to facilitate your business’s prosperity through strategic solutions and seamless growth. At Hypersage, we are committed to unleashing your digital potential, ensuring your triumph in the dynamic landscape of the digital world. Join us in elevating your business digitally, as we work hand-in-hand to pave the way for your path to prosperity
            </p>
            <h1 className="clienttrust_heading1">
              Trust is Very <span className="clienttrust_span">Important</span>
            </h1>
            <p className="clienttrust_paragraph2">
            This Highlights our skills, experience and quality of work, demonstrating our commitment to excellence.

            </p>
          </div>
          {/* card section */}
          <div className="clienttrust_all_product">
            <div className="clienttrust_product">
              <div className="clienttrust_product_info">
                <h1 className="clientrust_product-heading1">{experience}+</h1>
                <p className="clienttrust_product_paragraph1">
                  Years of Experience
                </p>
              </div>
            </div>
            {/* second */}
            <div className="clienttrust_product">
              <div className="clienttrust_product_info">
                <h1 className="clientrust_product-heading2">{clients}+</h1>
                <p className="clienttrust_product_paragraph2">Happy Clients</p>
              </div>
            </div>
            {/* third */}
            <div className="clienttrust_product">
              <div className="clienttrust_product_info">
                <h1 className="clientrust_product-heading3">{employees}+</h1>
                <p className="clienttrust_product_paragraph3">Employees</p>
              </div>
            </div>
            {/* four */}
            <div className="clienttrust_product">
              <div className="clienttrust_product_info">
                <h1 className="clientrust_product-heading4">{offices}+</h1>
                <p className="clienttrust_product_paragraph_4">
                  Brands Across Countries
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Clienttrust;
