import React from "react";
import "../Pricinghomepage/Pricinghome.css";
import { Link } from "react-router-dom";
import LetsBuildPage from "../LetsBuildPage/LetsBuildPage";

const PricingHome = () => {
  return (
    <>
      <div className="PricingHome_main_body">
        <div className="pricinghome_top_body_content">
          <h4 className="pricinghome_heading1">Pricing</h4>
          <h2 className="pricinghome_heading2">
            Choose The <span className="pricinghome_span">Plan & Pricing</span>{" "}
          </h2>
        </div>
        {/* card section */}
        <div className="PricingHome_Card_body">
          <div className="Pricinghome_page_container">
            <div className="pricinghomepage_card">
              <div className="pricinghomepage_imgbx">
                <h3 className="pricinghomepage_heading3">Essential SMS Plan</h3>
              </div>
              <div className="pricinghomepage_contentbx">
                <h2 className="pricinghomepage_heading4">
                  ₹0.14
                  <span className="pricinghomepage_span2">/SMS</span>
                </h2>
                <Link to="/sms-service" >
                  <button className="pricinghomepage_a">Get Started</button>
                </Link>
              </div>
            </div>
            {/* 2nd card */}
            <div className="pricinghomepage_card">
              <div className="pricinghomepage_imgbx">
                <h3 className="pricinghomepage_heading3">Bulk Whatsapp Plan</h3>
              </div>
              <div className="pricinghomepage_contentbx">
                <h2 className="pricinghomepage_heading4">
                  ₹0.08<span className="pricinghomepage_span2">/SMS</span>
                </h2>
                <Link to="/bulk-whatsapp" >
                  <button className="pricinghomepage_a">Get Started</button>
                </Link>
              </div>
            </div>
            {/* 3rd card */}
            <div className="pricinghomepage_card">
              <div className="pricinghomepage_imgbx">
                <h3 className="pricinghomepage_heading5">
                  Basic Software Solutions Plan
                </h3>
              </div>
              <div className="pricinghomepage_contentbx">
                <h2 className="pricinghomepage_heading4">
                  ₹5999
                </h2>
                <Link to="/software-solution" >
                   <button className="pricinghomepage_a">Get Started</button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* contact us page card response */}
      </div> 
      <LetsBuildPage/>
    </>
  );
};
export default PricingHome;
