import React from "react";
import "../Digitalfile page/AlllogoPage.css";

import NewLogo from "../../Assets/new logo.png";
const AlllogoPage = () => {
  return (
    <>
      <div id="AlllogoPage_main_body">
        <div id="alllogopage_body">
          <div id="alllogopage_heading1">
            <h2 id="alllogopage_heading2">Software Solutions</h2>
          </div>
          {/* First image with Text */}
          <div id="alllogopage_bodys">
            <div id="alllogopage_row">
              <div id="alllogopage_imgwrapper">
                <div id="alllogopage_card">
                  {/* <img
                    src={SoftwareLogo}
                    alt=""
                    id="alllogopage_softwarelogo1"
                  />
                  <img
                    src={softwarelogo2}
                    alt=""
                    id="alllogopage_softwarelogo2"
                  /> */}

                  <img src={NewLogo} alt="" id="NewLogoImage_id" />
                </div>
              </div>
              {/* content */}
              <div id="alllogopage_contentwrapper">
                <div id="alllogopage_content">
                  <p id="alllogopage_paragraph1">
                  Revolutionize your operations with our cutting-edge software solutions tailored to meet your unique business needs. From intuitive applications to robust enterprise software, we leverage the latest technologies to drive efficiency and innovation.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* second image with text */}
        </div>
      </div>
    </>
  );
};
export default AlllogoPage;
