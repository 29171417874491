import React from "react";
import {useEffect} from 'react';
import "./About.css";
import LetsBuildPage from "../LetsBuildPage/LetsBuildPage"
const AboutComponent = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  return (
    <>
      <div>
        <div className="About_page_container">
          <div className="Aboutpage_content">
            <h1 className="aboutpage_heading1">About Us</h1>
            <p className="aboutpage_paragraph1">
              At Hypersage, we redefine success in the digital realm. As a
              premier provider of comprehensive software solutions and digital
              marketing services, we empower businesses to thrive in the
              ever-evolving landscape of the online world.
            </p>
          </div>
        </div>

        <section className="about-us">
          <h2 className="aht"> About Hypersage Technology</h2>
          <p className="ah">
            At Hypersage Technology, we are dedicated to revolutionizing the
            digital landscape. With a passion for innovation and a commitment to
            excellence, we empower businesses to achieve remarkable growth in
            the online realm.
          </p>
        </section>

        <div className="Aboutpage-card-section">
          <Card
            title="Our Mission"
            content="Our mission is to redefine success by leveraging cutting-edge technology and strategic insights. We strive to be the catalyst that propels businesses into the digital forefront, enabling them to flourish and thrive."
          />
          <Card
            title="What We Do"
            content="At Hypersage Technology, we specialize in comprehensive digital solutions that drive tangible results. From bespoke software development to data-driven marketing strategies, we offer a suite of services tailored to elevate your business in the digital sphere."
          />
          <Card
            title="Our Approach" className="ourap"
            content="We believe in a collaborative approach, working closely with our clients to understand their unique needs and objectives. Our team of experts combines technical prowess with creative ingenuity, delivering solutions that resonate with your audience and drive sustainable growth."
          />
        </div>
      </div>
      {/* <LetsBuildPage/> */}
    </>
  );
};

const Card = ({ title, content }) => {
  return (
    <div className="aboutpage_card">
      <h3>{title}</h3>
      <p>{content}</p>
    </div>
  );
};

export default AboutComponent;
