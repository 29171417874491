import React,{useEffect,useState,useMemo} from "react";
import { Link } from "react-router-dom";
import smslogo from "../../Assets/sms logo1.png";
import smslogo2 from "../../Assets/sms logo2.png";
import smslogo3 from "../../Assets/sms logo 3.png";
import smslogo4 from "../../Assets/sms logo 4.png";
import smslogo5 from "../../Assets/sms logo5.png";
import smslogo6 from "../../Assets/sms logo6.png";
import "../SMSSERVICE PAGE/Smspage.css";
import LetsBuildPage from "../LetsBuildPage/LetsBuildPage";

const Smspage = () => {
  const [formData, setFormData] = useState({
    name: '',
    number: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    showPleaseWaitMessage();

    const formDataToSubmit = new FormData();
    formDataToSubmit.append('name', formData.name);
    formDataToSubmit.append('number', formData.number);
    formDataToSubmit.append('email', formData.email);
    formDataToSubmit.append('message', formData.message);

    fetch('https://script.google.com/macros/s/AKfycbwV_Zhdx2P9QEYt1tqfXGgFekSaIw8CGqH5soa_XiU2GU8-D55tsOPjfty-9zzgprY2QA/exec', {
      method: 'POST',
      body: formDataToSubmit
    })
    .then(response => response.text())
    .then(data => {
      console.log(data); // Handle response from server if needed
      alert('Form submitted successfully!');
      setFormData({ name: '', number: '', message: '',email:"" });
     
    })
    .catch(error => {
      console.error('Error:', error);
      alert('An error occurred while submitting the form.');
    })
    .finally(() => {
      hidePleaseWaitMessage();
    });
  };

  const showPleaseWaitMessage = () => {
    const submitButton = document.getElementById('submitButton');
    submitButton.innerText = 'Please wait...';
    submitButton.disabled = true;
  };

  const hidePleaseWaitMessage = () => {
    const submitButton = document.getElementById('submitButton');
    submitButton.innerText = 'Submit';
    submitButton.disabled = false;
  };

  const memoizedFormData = useMemo(() => ({
    name: formData.name,
    number: formData.number,
    email: formData.email,
    message: formData.message
  }), [formData]);
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  return (
    <>
    <div id="NSmspage_main_body">
      <div id="NSmspage_backgroundimage">
        <div id="NsmsPage_Hero_Image">
          <div id="nsmspage_hero_content">
            <div id="nsmspage_hero-content_inner">
              <h2 id="nsmspahe_heading_One">SMS Service</h2>
              <p id="nsmspage_Paragraph_one">
                Unleash the Power of HyperMessaging with Hypersage!
              </p>
            </div>
          </div>
        </div>
        {/*  */}
        <div id="nsmspage_paras_all">
          <p id="nsmspage_paras_One">
            Are you ready to elevate your promotional game? Look no further
            than Hypersage, your ultimate partner in propelling your brand to
            new heights through the art of SMS marketing.
          </p>
          <p id="nsmspage_paras_two">
            At Hypersage, we understand the importance of effective
            communication in today's fast-paced digital world. That's why
            we've perfected the science of HyperMessaging – a dynamic approach
            to promotional SMS that goes beyond the ordinary.
          </p>
          <p id="nsmspage_Paras_three">
            At Hypersage, we are committed to turning your vision into
            reality. With a focus on quality, efficiency, and client
            satisfaction, we take pride in being the go-to partner for
            businesses seeking top-notch software expertise. Experience the
            power of tailored solutions with Hypersage and transform your
            digital landscape today.
          </p>
          <p id="nsmspage_paras_four">
            Are you ready to revolutionize your promotional efforts? Join the
            HyperRevolution with Hypersage and experience the difference that
            HyperMessaging can make for your brand. From boosting brand
            awareness to driving sales, we've got your SMS marketing needs
            covered. Get in touch with us today and let's embark on a
            journey of hyper-connectivity and unparalleled impact!
          </p>
        </div>
        {/* CARD SECTION ONE*/}
        <div id="nsmspage_card_container">
          <div id="nsmspage_card_content_section">
            <div id="nsmspage_Card">
              <img src={smslogo} alt="" id="nsmspage_image1" />
              <div id="nsmspage_card_Button">
                <button id="nsmspage_button-under">Buy Now</button>
              </div>
            </div>
            <div id="nsmspage_Card">
              <img src={smslogo2} alt="" id="nsmspage_image1" />
              <div id="nsmspage_card_Button">
                <button id="nsmspage_button-under">Buy Now</button>
              </div>
            </div>
            <div id="nsmspage_Card">
              <img src={smslogo3} alt="" id="nsmspage_image1" />
              <div id="nsmspage_card_Button">
                <button id="nsmspage_button-under">Buy Now</button>
              </div>
            </div>
          </div>
        </div>
        {/* CARD SECTION TWO */}
        <div id="nsmspage_card_container">
          <div id="nsmspage_card_content_section">
            <div id="nsmspage_Card">
              <img src={smslogo4} alt="" id="nsmspage_image1" />
              <div id="nsmspage_card_Button">
                <button id="nsmspage_button-under">Buy Now</button>
              </div>
            </div>
            <div id="nsmspage_Card">
              <img src={smslogo5} alt="" id="nsmspage_image1" />
              <div id="nsmspage_card_Button">
                <button id="nsmspage_button-under">Buy Now</button>
              </div>
            </div>
            <div id="nsmspage_Card">
              <img src={smslogo6} alt="" id="nsmspage_image1" />
              <div id="nsmspage_card_Button">
                <button id="nsmspage_button-under">Click Here</button>
              </div>
            </div>
          </div>
        </div>
        {/* BUILD FUTURE TOGETHER */}
        <div id="NSMSPAGE_CONTACT_BODY">
          <div id="NSMSPAGE_FORM-CONTAINER">
            <div id="NSMSPAGE_FORM_CONTACT_INFO">
              <div id="NSMSPAGE_FORM-BOX">
                <div id="NSMSPAGE_FORM_TEXT">
                  <h2 id="NSMSPAGE_FORM_TEXT2">Let's Build</h2>
                  <h2 id="NSMAPHE_FORM_TEXT3">
                    <span id="NSMSPAGE_SPAN_FORM">Future</span> Together
                  </h2>
                  <p id="NSMSPAGE_PARAGRAPH_ONE">
                    Get In Touch; Together,Let's Shape a Prosperous Future.
                  </p>
                </div>
              </div>
            </div>
            {/* form */}
            <div id="NSMSPAGE_CONTACT_FORM_BODY">
              <div id="NSMSPAGE_ALL_FORM">
              <form onSubmit={handleSubmit} id="NSMSPAGE_FORM">
      <div id="NSMSPAGE_FORM_TITLE">Need Help?</div>
      <div id="NSMSPAGE_FORM_HALF">
        <div id="NSMSPAGE_ITEMS_FORM">
          <input
            type="text"
            name="name"
            id="NSMSPAGE_INPUT_BOX"
            value={memoizedFormData.name}
            onChange={handleChange}
            placeholder="Name"
          />
        </div>
        <div id="NSMSPAGE_ITEMS_FORM">
          <input
            type="number"
            name="number"
            id="NSMSPAGE_INPUT_BOX_Two"
            value={memoizedFormData.number}
            onChange={handleChange}
            placeholder="Number"
          />
        </div>
      </div>
      <div id="NSMSPAGE_ITEMS_FORM">
        <input
          type="email"
          name="email"
          id="NSMSPAGE_INPUT_BOX_Two"
          value={memoizedFormData.email}
          onChange={handleChange}
          placeholder="Email ID"
        />
      </div>
      <div id="NSMSPAGE_Form_Full">
        <textarea
          name="message"
          style={{color:"#000"}}
          value={memoizedFormData.message}
          onChange={handleChange}
          placeholder="Your Message"
        ></textarea>
      </div>
      <div id="NSMSPAGE_Form_Action">
      <button type="submit" id="submitButton" onClick={handleSubmit} className="btn">Submit</button>
      </div>
    </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  );
};
export default Smspage;
