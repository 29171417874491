import React from "react";
import Marquee from "react-fast-marquee";
import slidelogo from "../../Assets/slidelogo.png";
import Slidelogo2 from "../../Assets/slidelogo2.png";
import Slidelogo3 from "../../Assets/slidelogo3.png";
import Slidelogo4 from "../../Assets/slidelogo4.png";
import Slidelogo5 from "../../Assets/slidelogo5.png";
import Slidelogo6 from "../../Assets/slidelogo6.png";
import Slidelogo7 from "../../Assets/slidelogo7.png";
import Slidelogo8 from "../../Assets/slidelogo8.png";
import Slidelogo9 from "../../Assets/slidelogo9.png";

const Slide = () => {
  return (
    <>
      <div>
        <Marquee
          speed={100}
          style={{
            height: "20vh",
            width: "100%",
            backgroundColor: "white",
            marginTop: 30,
            overflow: "hidden",
          }}
        >
          <div className="slidelogo1">
            <img src={slidelogo} alt="" style={{ width: 200, height: 200 }} />
          </div>
          <div className="slidelogo1">
            <img src={Slidelogo2} alt="" style={{ width: 200, height: 200 }} />
          </div>
          <div className="slidelogo1">
            <img src={Slidelogo3} alt="" style={{ width: 200, height: 200 }} />
          </div>
          <div className="slidelogo1">
            <img src={Slidelogo4} alt="" style={{ width: 200, height: 200 }} />
          </div>
          <div className="slidelogo1">
            <img src={Slidelogo5} alt="" style={{ width: 200, height: 200 }} />
          </div>
          <div className="slidelogo1">
            <img src={Slidelogo6} alt="" style={{ width: 200, height: 200 }} />
          </div>
          <div className="slidelogo1">
            <img src={Slidelogo7} alt="" style={{ width: 200, height: 200 }} />
          </div>
          <div className="slidelogo1">
            <img src={Slidelogo8} alt="" style={{ width: 200, height: 200 }} />
          </div>
          <div className="slidelogo1">
            <img src={Slidelogo9} alt="" style={{ width: 200, height: 200 }} />
          </div>
        </Marquee>
      </div>
    </>
  );
};
export default Slide;
