import React from "react";
import "../Digitalfile page/AlllogoPage2.css";
import NewLogo2 from "../../Assets/new logo2.png";
const Alllogopage2 = () => {
  return (
    <>
      <div id="AlllogoPage_main_body">
        <h1 id="AlllogoPage2_heading1">Digital Marketing Mastery</h1>
        <div id="AlllogoPage2_body">
          <div id="AlllogoPage2_row">
            {/* Content */}
            <div id="AlllogoPage2_contentWrapper2">
              <div id="Alllogopage2_content2">
                <p id="AlllogoPage2_paragraph1">
                Step into the realm of unparalleled digital marketing prowess with Hypersage. Our comprehensive digital marketing solutions are meticulously crafted to boost your online presence, engage your target audience, and drive conversions. From strategic social media campaigns to data-driven SEO strategy, we harness the power of digital marketing to amplify your brand and propel your business to new heights.
                </p>
              </div>
            </div>
            {/* Second image Card */}
            <div id="AlllogoPage2_imgwrapper2">
              <div id="Alllogopage2_card2">
                {/* <img src={Softwarelogo} alt="" id="SomeSoftwareimage" /> */}
                <img src={NewLogo2} alt="" id="NewLogo2Id" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Alllogopage2;
