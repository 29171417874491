import React,{useState,useMemo} from "react";
import "../LetsBuildPage/LetsBuildPage.css";
const LetsBuildPage = () => {
  const [selectedOption, setSelectedOption] = useState("");
  // useEffect(() => {
  //   // 👇️ scroll to top on page load
  //   window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  // }, []);
  const [formData, setFormData] = useState({
    name: '',
    number: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    showPleaseWaitMessage();

    const formDataToSubmit = new FormData();
    formDataToSubmit.append('name', formData.name);
    formDataToSubmit.append('number', formData.number);
    formDataToSubmit.append('email', formData.email);
    formDataToSubmit.append('message', formData.message);

    fetch('https://script.google.com/macros/s/AKfycbyRNuF8sDfmfFbEWsy6Xmqf8Cv6SDbW7mpaiTcJ43ly37onNNnqtKvwaxvkmQ3x1oe-/exec', {
      method: 'POST',
      body: formDataToSubmit
    })
    .then(response => response.text())
    .then(data => {
      console.log(data); // Handle response from server if needed
      alert('Form submitted successfully!');
      setFormData({ name: '', number: '', message: '',email:"" });
     
    })
    .catch(error => {
      console.error('Error:', error);
      alert('An error occurred while submitting the form.');
    })
    .finally(() => {
      hidePleaseWaitMessage();
    });
  };

  const showPleaseWaitMessage = () => {
    const submitButton = document.getElementById('submitButton');
    submitButton.innerText = 'Please wait...';
    submitButton.disabled = true;
  };

  const hidePleaseWaitMessage = () => {
    const submitButton = document.getElementById('submitButton');
    submitButton.innerText = 'Submit';
    submitButton.disabled = false;
  };

  const memoizedFormData = useMemo(() => ({
    name: formData.name,
    number: formData.number,
    email: formData.email,
    message: formData.message
  }), [formData]);
  return (
    <>
      <div id="LetsBuildPage_contact_body">
        <div id="LetsBuildPage_Form_container">
          <div id="LetsBuildPage_Form_Contactinfo">
            <div id="LetsBuildPage_Form_Box">
              <div id="LetsBuildPage_Form_Text">
                <h2 id="LetsBuildPage_Form_Text2">Let's Build</h2>
                <h2 id="LetsBuildPage_Form_Text3">
                  <span id="LetsBuildPage_span_form">Future</span> Together
                </h2>
                <p id="LetsBuildPage_Paragraph1">
                  Get in Touch; Together,Let's Shape a Prosperous Future.
                </p>
              </div>
            </div>
          </div>
          {/*  */}
          
          {/* LetsBuildpage Contact Form */}
          <div id="LetsBuildPage_Contact_Form_Body">
            <div id="LetsBuildPage_All_form">
              <form action="" id="LetsBuildPage_needHelp_form">
                <div id="LetsBuildPage_Form_Title">Need Help?</div>
                <form >
                  <div id="LetsBuildPage_Items_Form">
                  <label htmlFor="Name" style={{color:"white"}}>Name *</label>
                  <br></br>
                    <input
                      type="text"
                      name="name"
                      id="LetsBuildPage_Form_InputBox"
                      placeholder="Enter Your Name"
                      value={memoizedFormData.name} onChange={handleChange}
                    />
                  </div>
                </form>
               
                <div id="LetsBuildPage_Items_Form">
                <label style={{color:"white"}} htmlFor="Name">Number *</label>
                <br></br>
                    <input
                      type="number"
                      name="number"
                      id="LetsBuildPage_Form_InputBox"
                      placeholder="Enter Your Number"
                      value={memoizedFormData.number} onChange={handleChange}
                    />
                  </div>
                <div id="LetsBuildPage_Items_Form">
                <label style={{color:"white"}} htmlFor="Name">Email *</label>
                <br></br>
                    <input
                      type="email"
                      name="email"
                      id="LetsBuildPage_Form_InputBox"
                      placeholder="Enter Your Email I'D"
                      value={memoizedFormData.email} onChange={handleChange}
                    />
                  </div>
              
                <div id="LetsBuildPage_Form_Full">
                <label style={{color:"white"}} htmlFor="Name">Message *</label>
                <br></br>
                <br></br>
                  <textarea
                    name="message"
                    id="LetsBuildPage_Textarea_form"
                    placeholder="Your Message"
                    value={memoizedFormData.message} onChange={handleChange}
                    style={{color:"#000"}}
                  ></textarea>
                </div>
                <div id="LetsBuildPage_Form_Action">
                <button type="submit" id="submitButton" onClick={handleSubmit} className="btn">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default LetsBuildPage;
