import React from "react";
import "../NewSigninPage/NSignInPage.css";
import Logo1 from "../../Assets/logo.png";
import { Link } from "react-router-dom";
import Googlelogo from "../../Assets/Googleimage.png";
import Facebooklogo from "../../Assets/facebookImage.png";
import AppleLogo from "../../Assets/Appleimage.png";

const NSignInPage = () => {
  return (
    <>
      <div id="NSignInpage_Contact_Main_Body">
        <div id="NSigninpage_Form_container">
          <div id="NSigninpage_Form_contactinfo">
            <div id="NSigninpage_Form_box">
              <div id="NSigninpage_Form_text">
                <img src={Logo1} alt="" id="NSigninpage_Images" />
                <h2 id="NSigninpage_text1">Your Digital Growth Partner</h2>
              </div>
            </div>
          </div>
          {/* NSiGNINPAGE FORM */}
          <div id="Nsigninpage_contact_form_body">
            <div id="Nsigninpage_All_form">
              <form action="" id="Nsigninpage_form">
                <div id="Nsigninpage_Form_Title">Sign In</div>
                <div id="Nsigninpage_form_title-Two">
                  New User?{" "}
                  <span id="Nsigninpage_span_one">Create an account</span>
                </div>
                <div id="Nsigninpage_input-box1">
                  <label htmlFor="" id="Nsigninpage_label_One">
                    Email I'D
                  </label>
                  <br />
                  <input type="email" id="Nsigninapeg_First_InPut_Box" />
                </div>
                <div id="Nsigninpage_input_box2">
                  <label htmlFor="" id="Nsigninpage_label_two">
                    Password
                  </label>
                  <br />
                  <input type="text" id="Nsigninapge_Second-input-Box" />
                </div>
                <div id="Nsigninpage_forget">
                  <Link id="Nsigninpage_Link-forget">Forgotten password?</Link>
                </div>
                <div id="Nsigninpage_Submit_Button">
                  <button id="Nsigninapeg_Button_One">Login</button>
                </div>
                <div id="Nsigninpage_or">
                  <hr id="nsigninpahe_hr1" /> <p id="Nsignipage_or_para">OR</p>{" "}
                  <hr id="Nsigninpage_hr-two" />
                </div>

                <div id="Nsigninpage_social_icons">
                  <div id="Nsigninpage_First_icon">
                    <img src={Googlelogo} alt="" id="Nsigninpage_icon_Image1" />
                  </div>
                  <div id="Nsigninpage_First_icon">
                    <img
                      src={Facebooklogo}
                      alt=""
                      id="Nsigninpage_icon_Image1"
                    />
                  </div>
                  <div id="Nsigninpage_First_icon">
                    <img src={AppleLogo} alt="" id="Nsigninpage_icon_Image1" />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default NSignInPage;
