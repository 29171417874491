import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "../Testimonal page/Testimonial.css";
import { EffectCoverflow, Pagination, Autoplay } from "swiper/modules";
import Testimoniallogo1 from "../../Assets/Testominal logo1.png";
import Testimoniallogo2 from "../../Assets/Testominal logo2.png";
import Testimoniallogo3 from "../../Assets/Testominal logo3.png";
import Testimoniallogo4 from "../../Assets/Testimonallogo4.png";
import Testimoniallogo5 from "../../Assets/Testimonallogo5.png";
import Testimoniallogo6 from "../../Assets/Testmonallogo6.png";

const TestimonialPage = () => {
  return (
    <div className="TestimonialPage_body">
      <div className="TestimonalPage_content">
        <h1>
          Our Client <span className="testimonialpage_span">Testimonial</span>
        </h1>
      </div>
      <Swiper
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 1,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        loop={true}
        spaceBetween={250}
        pagination={true}
        modules={[EffectCoverflow, Autoplay]}
        className="mySwiper"
        id="Myswipey"
      >
        <SwiperSlide>
          <div className="Testimonialpage_box">
            <img src={"https://firebasestorage.googleapis.com/v0/b/hypersage-a9736.appspot.com/o/rrrrrr%2FTestominal%20logo1.png?alt=media&token=a6e2bcae-6c56-4f82-9c1c-aee50bd2d58c"} alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <img src={"https://firebasestorage.googleapis.com/v0/b/hypersage-a9736.appspot.com/o/rrrrrr%2FTestominal%20logo2.png?alt=media&token=154a23cd-11db-4eb2-9514-e7a20ad4f63f"} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={"https://firebasestorage.googleapis.com/v0/b/hypersage-a9736.appspot.com/o/rrrrrr%2FTestominal%20logo3.png?alt=media&token=4af64b27-9bb3-49b5-90ac-cbc4b3501082"} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={"https://firebasestorage.googleapis.com/v0/b/hypersage-a9736.appspot.com/o/rrrrrr%2FTestimonallogo4.png?alt=media&token=3a1c835d-bfec-4a12-8cea-1eb8a8976d78"} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={"https://firebasestorage.googleapis.com/v0/b/hypersage-a9736.appspot.com/o/rrrrrr%2FTestimonallogo5.png?alt=media&token=78636c1b-baac-4926-ba50-c6782e682c16"} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={"https://firebasestorage.googleapis.com/v0/b/hypersage-a9736.appspot.com/o/rrrrrr%2FTestmonallogo6.png?alt=media&token=ed06377e-8214-46c7-aa43-ca790968e0e1"} alt="" />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};
export default TestimonialPage;
